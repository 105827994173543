import CookieConsent from "react-cookie-consent";

import {
  GoogleReCaptchaProvider
} from 'react-google-recaptcha-v3';

import React, { Fragment } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Speech from "speak-tts";
// import Speech from 'react-speech';

// @material-ui/icons
import Camera from "@material-ui/icons/Camera";
import SubjectIcon from "@material-ui/icons/SubjectRounded";
import InputIcon from "@material-ui/icons/InputRounded";
// import AccessibleForwardRoundedIcon from "@material-ui/icons/AccessibleForwardRounded";

import HeartIcon from "@material-ui/icons/FavoriteBorderRounded";
import PhoneIcon from "@material-ui/icons/PhoneRounded";
// import AccessibilityNewIcon from "@material-ui/icons/AccessibilityNewRounded";
//import css module

// core components
import LoginPageModal from 'views/LoginPage/LoginPageModal'
import config from "config"
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import profile from "assets/img/MAHLogo.png";

import studio1 from "assets/img/250/p1-250x250.jpg";
import studio3 from "assets/img/hall.jpg";
import studio4 from "assets/img/examples/studio-4.jpg";
import work1 from "assets/img/250/fmmHall-250x250.jpg";
import work3 from "assets/img/examples/cynthia-del-rio.jpg";
import KDAE_CareFolderView from 'views/KDAE_CareFolderView/KDAE_CareFolderView'


import profilePageStyle from "assets/jss/material-kit-react/views/profilePage.jsx";
import { withLangStore } from "stores/KLangProvider.js";
import { AccessibilityNewRounded ,AccessibilityNew,AccessibleForwardRounded} from "@mui/icons-material";

class Home extends React.Component {
  state = {
    open: false,
    openCareFolderView: false,
    langStoreReady: false,
    // focusOnTabNumber: 0
  };

  navPills = React.createRef()

  handleButtonPress = (number) => {
    this.buttonPressTimer = setTimeout(() => {
      this.handleSpeech(number)
    }, 1000);
  }
  handleButtonRelease = () => {
    clearTimeout(this.buttonPressTimer);
  }
  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('stopRedirect');
    if(myParam){
       if(!!myParam===true){
        alert('stopRedirect activated')
        window.localStorage.setItem('stopRedirect', true)
      }
      else {
        alert('stopRedirect desactivated')
        window.localStorage.removeItem('stopRedirect')
      }
    }

    this.props.initLangStore().then(data => {
      this.setState({ langStoreReady: true })
    })
  }
  handleOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  handleOpenCareFolderView = () => {
    this.setState({ openCareFolderView: true });
  };
  handleCloseCareFolderView = (success = false) => {
    this.setState({ openCareFolderView: false });
    this.setFocusOnNavPillsTab( success === true ? 1 : 0 )

    // this.setState({ focusOnTabNumber: success === true ? 1 : 0 }) // pass to step 2
  };
  setFocusOnNavPillsTab = (number) => {
    this.navPills.current.handleChangeIndex(number)
  }
  handleSpeech = (sentenceNumber) => {
    if (sessionStorage.textToSpeechEnable === 'true' || !("textToSpeechEnable" in sessionStorage)) { } else { return }
    let textToSpeech = []
    const { langStore } = this.props
    switch (sentenceNumber) {
      case 1:
        textToSpeech = langStore.home.title.speech
        break;
      case 2:
        textToSpeech = langStore.home.intro.speech
        break;
      case 3:
        textToSpeech = langStore.home.step1.speech
        break;
      case 4:
        textToSpeech = langStore.home.step1More.speech
        break;
      case 5:
        textToSpeech = langStore.home.step2.speech
        break;
      case 6:
        textToSpeech = langStore.home.step2More.speech
        break;
      case 7:
        textToSpeech = langStore.home.step3.speech
        break;
      default:
        textToSpeech = []
    }
    if (textToSpeech.length < 1) return
    const speech = new Speech();
    // speech.setVoice('Fiona')
    let lang = sessionStorage.prefsLang || 'fr_FR'
    speech.setLanguage(lang)
    speech.init({
      'lang': lang
    }).then(data => {
      console.log("Speech is ready", data);
    })
    textToSpeech.map(textToSpeech =>
      speech
        .speak({
          text: textToSpeech.speech,
          queue: textToSpeech.queue,
          listeners: {
            onstart: () => {
              console.log("Start utterance");
            },
            onend: () => {
              console.log("End utterance");
            },
            onresume: () => {
              console.log("Resume utterance");
            },
            onboundary: event => {
              console.log(
                event.name +
                " boundary reached after " +
                event.elapsedTime +
                " milliseconds."
              );
            }
          }
        })
        .then(data => {
          console.log("Success !", data);
        })
        .catch(e => {
          console.error("An error occurred :", e);
        })
    )
  }
  render() {
    const { classes, langStore, ...rest } = this.props;
    const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
    if (this.state.langStoreReady === false) {
      return <Fragment>chargement des langues</Fragment>
    }
    // return <div>{JSON.stringify(langStore.home.mahLogo)}</div>
    return (
      <div className="home">
        <Header
          color="transparent"
          brand=""
          brandImage={require("assets/img/header-logo-catho.png")}
          rightLinks={
            <HeaderLinks
              isModal={false}
            />}
          fixed
          changeColorOnScroll={{
            height: 200,
            color: "dark"
          }}
          {...rest}
        />
        <Parallax small filter image={require("assets/img/facade2.jpg")} />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div>
            <div className={classes.container}>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={10}>
                  <div className={classes.profile}>
                    <div>
                      <img src={profile} alt={langStore.home.mahLogo.text} />
                    </div>
                    <div className={classes.name}>
                      <h1 className={classes.title}
                        onTouchStart={e => this.handleButtonPress(1)}
                        onTouchEnd={this.handleButtonRelease}
                        onDoubleClick={e => this.handleSpeech(1)}>
                        {langStore.home.title.text}
                      </h1>
                    </div>
                  </div>
                </GridItem>
              </GridContainer>
              <div className={classes.description}>
                <h3
                  onTouchStart={e => this.handleButtonPress(2)}
                  onTouchEnd={this.handleButtonRelease}
                  onDoubleClick={e => this.handleSpeech(2)}>
                  {langStore.home.intro.text}
                </h3>
              </div>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={10} className={classes.navWrapper}>
                  <NavPills
                    ref={this.navPills}
                    // active={this.state.focusOnTabNumber}
                    alignCenter
                    color="primary"
                    tabs={[
                      {
                        tabButton: langStore.home.step1TabButton.text,
                        tabIcon: Camera,
                        tabContent: (
                          <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={4}>

                              <Paper elevation={1} className={classes.paper} >
                                <Typography variant="h5" component="h3">
                                  <SubjectIcon alt={langStore.home.step1Alt.text}> </SubjectIcon> {langStore.home.step1Title.text}<br></br>
                                </Typography>
                                <Typography component="span"
                                  onTouchStart={e => this.handleButtonPress(3)}
                                  onTouchEnd={this.handleButtonRelease}
                                  onDoubleClick={e => this.handleSpeech(3)}>
                                  <br></br>
                                  {langStore.home.step1.text}
                                </Typography>
                                <Button variant="contained" color="secondary" role="button" aria-label={langStore.home.step1ButtonLabel.text} className={classes.button} onClick={this.handleOpenCareFolderView}>
                                  {langStore.home.signUpButton.text}
                                </Button>
                              </Paper>
                              <img
                                alt=""
                                src={studio1}
                                className={navImageClasses}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                              <Paper elevation={1} className={classes.paper} >
                                <Typography variant="h5" component="h3">
                                  <InputIcon alt={langStore.home.step1RenewAlt.text}> </InputIcon> {langStore.home.step1RenewTitle.text}<br></br>
                                </Typography>
                                <Typography component="span"
                                  onTouchStart={e => this.handleButtonPress(3)}
                                  onTouchEnd={this.handleButtonRelease}
                                  onDoubleClick={e => this.handleSpeech(3)}>
                                  <br></br>
                                  {langStore.home.step1Renew.text}
                                </Typography>
                                <Button variant="contained"
                                  color="secondary"
                                  role="button"
                                  aria-label={langStore.home.step1ButtonLabel.text}
                                  className={classes.button}
                                  onClick={this.handleOpen}>
                                  {langStore.home.renewButton.text}
                                </Button>

                              </Paper>
                              <img
                                alt="..."
                                src={studio4}
                                className={navImageClasses}
                              />
                              <Paper elevation={1} className={classes.paper}>
                                <Typography component="span"
                                  onTouchStart={e => this.handleButtonPress(4)}
                                  onTouchEnd={this.handleButtonRelease}
                                  onDoubleClick={e => this.handleSpeech(4)}>
                                  {langStore.home.step1More.text}
                                  {(!("prefsCountryCode" in sessionStorage) || ( sessionStorage.prefsCountryCode && sessionStorage.prefsCountryCode.toLowerCase() === 'fr')) ? (
                                    <a onClick={e => this.setFocusOnNavPillsTab(1)} className={classes.a}>(cf étape 2)</a>
                                  ) : (
                                      <a onClick={e => this.setFocusOnNavPillsTab(1)} className={classes.a}>(see step 2)</a>
                                    )

                                  }

                                  <br />
                                  <br />

                                </Typography>
                              </Paper>
                            </GridItem>
                          </GridContainer>
                        )
                      },
                      {
                        tabButton: langStore.home.step2TabButton.text,
                        tabIcon: HeartIcon,
                        tabContent: (
                          <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={4}>
                              <Paper elevation={1} className={classes.paper}
                                onTouchStart={e => this.handleButtonPress(5)}
                                onTouchEnd={this.handleButtonRelease}
                                onDoubleClick={e => this.handleSpeech(5)}>
                                <Typography variant="h5" component="h3" >
                                  <PhoneIcon> </PhoneIcon>{langStore.home.step2.text}
                                </Typography>
                                <Typography component="span">
                                  {langStore.home.step2Suite.text}


                                </Typography>
                              </Paper>
                              <img
                                alt="..."
                                src={work1}
                                className={navImageClasses}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                              <img
                                alt="..."
                                src={work3}
                                className={navImageClasses}
                              />

                              <Paper elevation={1} className={classes.paper}
                                onTouchStart={e => this.handleButtonPress(6)}
                                onTouchEnd={this.handleButtonRelease}
                                onDoubleClick={e => this.handleSpeech(6)}>
                                <Typography component="span">
                                  {langStore.home.step2More.text}
                                </Typography>
                              </Paper>
                            </GridItem>
                          </GridContainer>
                        )
                      },
                      {
                        tabButton: langStore.home.step3TabButton.text,
                        tabIcon: AccessibilityNewRounded,
                        tabContent: (
                          <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={4}>
                              <Paper elevation={1} className={classes.paper}
                                onTouchStart={e => this.handleButtonPress(7)}
                                onTouchEnd={this.handleButtonRelease}
                                onDoubleClick={e => this.handleSpeech(7)}>
                                <Typography variant="h5" component="h3">
                                  <AccessibilityNewRounded alt={langStore.home.step3Alt.text}> </AccessibilityNewRounded>{langStore.home.step3Title.text} <br></br>
                                </Typography>
                                <Typography component="span">
                                  {langStore.home.step3.text}
                                </Typography>
                              </Paper>
                              <img
                                alt="..."
                                src={studio1}
                                className={navImageClasses}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                              <img
                                alt="..."
                                src={studio3}
                                className={navImageClasses}
                              />
                              <Paper elevation={1} className={classes.paper}>
                                <Typography component="span"
                                  onTouchStart={e => this.handleButtonPress(7)}
                                  onTouchEnd={this.handleButtonRelease}
                                  onDoubleClick={e => this.handleSpeech(7)}>
                                  {langStore.home.step3More.text}
                                  {(!("prefsCountryCode" in sessionStorage) || sessionStorage.prefsCountryCode && sessionStorage.prefsCountryCode.toLowerCase() === 'fr') ? (
                                    <a onClick={e => this.setFocusOnNavPillsTab(0)} className={classes.a}>(cf étape 1)</a>
                                  ) : (
                                      <a onClick={e => this.setFocusOnNavPillsTab(0)} className={classes.a}>(see step 1)</a>
                                    )

                                  }
                                </Typography>
                              </Paper>
                            </GridItem>
                          </GridContainer>
                        )
                      }
                    ]}
                  />
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>
        <Footer />
        <GoogleReCaptchaProvider reCaptchaKey={config.reCaptcha.key}>

          <KDAE_CareFolderView
            open={this.state.openCareFolderView}
            handleClose={this.handleCloseCareFolderView}
          />
        </GoogleReCaptchaProvider>

        {/* <Speech text="I have the default settings" autostart={true}/> */}
        <LoginPageModal

          handleClose={this.handleClose} open={this.state.open} />
<CookieConsent
        location="bottom"
        containerClasses="alert alert-warning col-lg-6"
        buttonText="j'accepte"
        style={{width:'50%'}}
        >
Utilisation des cookies.<br/>En poursuivant votre navigation, vous acceptez l'utilisation de cookies
</CookieConsent>
      </div>
    );
  }
}

export default withStyles(profilePageStyle)(withLangStore(Home))
