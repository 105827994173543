import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(2),
        // minWidth: 180,
    },
    selectEmpty: {
        // marginTop: theme.spacing.unit * 2,
    },
});

class KDAESelect extends React.Component {
    state = {
        name: this.props.initialValue
    };
    handleChange = event => {
        this.setState({ name: event.target.value });
        if (this.props.handleInputChange)
            this.props.handleInputChange(event.target.value )
    };
    render() {

        let rowsMenuItems = this.props.datas.map(data => <MenuItem {...data}></MenuItem>)

        const { fullwidth, label, value, defaultCountry, onChange, classes, datas, id, name, ...onlyOtherProps } = this.props;
        return (
            <FormControl fullwidth={fullwidth} className={classes.formControl} {...onlyOtherProps}>
                <InputLabel htmlFor={id}>{label}</InputLabel>
                <Select
                    value={this.state.name}
                    onChange={this.handleChange}
                    inputProps={{
                        name: name,
                        id: id,
                    }}
                >{rowsMenuItems}
                </Select>
            </FormControl>
        );
    }
}

KDAESelect.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(KDAESelect);