import React from 'react'
import _ from 'lodash'
import { Route, Redirect } from 'react-router'

import config from '../../config'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import InputAdornment from '@material-ui/core/InputAdornment'
import Icon from '@material-ui/core/Icon'
// @material-ui/icons
import EmailIcon from '@material-ui/icons/Email'
import PeopleIcon from '@material-ui/icons/People'
import SchoolIcon from '@material-ui/icons/School'
// core components
import Header from 'components/KMLogin/Header/Header.jsx'
import Footer from 'components/KMLogin/Footer/Footer.jsx'
import GridContainer from 'components/KMLogin/Grid/GridContainer.jsx'
import GridItem from 'components/KMLogin/Grid/GridItem.jsx'
import Button from 'components/KMLogin/CustomButtons/Button.jsx'
import Card from 'components/KMLogin/Card/Card.jsx'
import CardBody from 'components/KMLogin/Card/CardBody.jsx'
import CardHeader from 'components/KMLogin/Card/CardHeader.jsx'
import CardFooter from 'components/KMLogin/Card/CardFooter.jsx'
import KMCustomInput from 'components/KMLogin/KMCustomInput/KMCustomInput.jsx'
import KMMaterialWaiting from 'components/KMLogin/KMMaterial/KMMaterialWaiting'
import KMMaterialSnackBar from 'components/KMLogin/KMMaterial/KMMaterialSnackBar'
import KMEstablishment from 'components/KMLogin/KMCustomInput/KMEstablishment'

import registerFormStyle from 'assets/jss/material-kit-react/views/loginPage.jsx'

import dico from '../../lang/back.fr.js'

class RegisterForm extends React.Component {
  constructor (props) {
    super(props)
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: 'cardHidden',
      viewMode: 'login',
      components: {
        KMMaterialWaiting: {
          displayWaiting: false
        }
      },
      forms:
        {
          email: {
    				errorText: '',
    				errorTextMessage: dico.login.error.email,
    				pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
    				hintText: dico.login.email,
            value: '',
            label: dico.login.email,
            id: 'email',
            success: false
          },
          password: {
    				errorText: '',
    				errorTextMessage: dico.login.error.password,
    				pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-.+~{[|\\^\]}%!@#$%^&*;,_])(?=.{5,})/,
    				hintText: dico.login.password,
            value: '',
            label: dico.login.password,
            id: 'password',
            success: false
          },
          firstName: {
    				errorText: '',
    				errorTextMessage: dico.login.error.firstName,
    				pattern: /.{1,}/,
    				hintText: dico.login.firstName,
            value: '',
            label: dico.login.firstName,
            id: 'firstName',
            success: false
          },
          lastName: {
    				errorText: '',
    				errorTextMessage: dico.login.error.lastName,
    				pattern: /.{2,}/,
    				hintText: dico.login.lastName,
            value: '',
            label: dico.login.lastName,
            id: 'lastName',
            success: false
          },
          establishmentId: {
    				errorText: '',
    				errorTextMessage: dico.login.error.establishmentId,
    				pattern: null,
    				hintText: dico.login.establishmentId,
            value: '',
            label: dico.login.establishmentId,
            id: 'establishmentId',
            success: false
          }
        }

    }

    this.handleInputChangeMaterial = this.handleInputChangeMaterial.bind(this)
    this.handleRegister = this.handleRegister.bind(this)
  }
  handleInputChangeMaterial (event) {
    const target = event.target
    let id = event.target.id
	  let value = target.type === 'checkbox' ? target.checked : target.value
	  let forms = _.extend(this.state.forms)
	  // prepare value to set
    forms[id].value = value
    this.setState(prevState => ({
		   forms: forms
	    }))
  }
  handleSelectChangeMaterial = (name,value) => {
    let forms = _.extend(this.state.forms)
	  // prepare value to set
    forms[name].value = value
    this.setState(prevState => ({
		   forms: forms
	    }))
  }
  customCheckField (field) {
    let {errorText, error, name} = this.checkField([field])
    const forms = _.extend(this.state.forms)
    forms[name].errorText = errorText
    forms[name].success = !error
    this.setState({forms: forms})
    return !error
  }
  checkField (fields) {
    let error = false
    let errorText = ''
    let value = ''
    const forms = _.extend(this.state.forms)
    let name

    fields.map(function (currentName) {
      if (error == true) { return }
      name = currentName
      if (
        forms[currentName].hasOwnProperty('pattern') &&
        forms[currentName].hasOwnProperty('errorText') &&
        forms[currentName].hasOwnProperty('errorTextMessage') &&
				forms[currentName].errorTextMessage != ''
      ) {
        value = forms[currentName].value
        if (!value.match(forms[currentName].pattern)) {
          error = true
          errorText = forms[currentName].errorTextMessage
        } else {
          errorText = ''
        }
      }
    })

    return {error, errorText, name}
  }

  async handleRegister (event) {
    this.props.displayWaitingOn()
    let error = true
    error = error & this.customCheckField('email')
    error = error & this.customCheckField('password')
    error = error & this.customCheckField('firstName')
    error = error & this.customCheckField('lastName')
    if (!!error !== false) {
      const oauthRegisterUrl = config.oauth.register.url
      let loggedIn = false
      let message = dico.login.error.oauth.notRegister
      
      try {
        let env = await fetch(oauthRegisterUrl, {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          credentials: 'include',
          method: 'POST',
          body: JSON.stringify({ email: this.state.forms.email.value, password: this.state.forms.password.value, firstName: this.state.forms.firstName.value, lastName: this.state.forms.lastName.value, establishmentId: this.state.forms.establishmentId.value })
        })
        let {code, data} = await env.json()
        message = data.message
        if (code === 'ok') {
          if (data.auth === true && data.token !== '') {
            window.localStorage.setItem('JWT', data.token)
            loggedIn = true
          }
        }
      } catch (error) {
        console.log(error)
        loggedIn = false
      }
      if (loggedIn === true) {
        this.props.openPrivateRoute()
        // window.location.href = 'https://mah.univ-catholille.app/'
      } else {
        // MessageActions.show(message)
      }
    }
    this.props.displayWaitingOff()
  }

  componentDidMount () {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function () {
        this.setState({ cardAnimaton: '' })
      }.bind(this),
      450
    )
  }
  render () {
    const { classes, ...rest } = this.props
    return (

      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={4}>
            <Card className={classes[this.state.cardAnimaton]}>
              <form className={classes.form}
                onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                    // Do code here
                    ev.preventDefault()
                    this.handleRegister()
                  }
                }}>

                <CardHeader color="primary" className={classes.cardHeader}>
                  <h4>{dico.login.title1}</h4>
                  <div className={classes.socialLine}>
                    <Button
                      color="white"
                      onClick={this.props.handleSwitchView}
                    >
                      {dico.login.connectNow}&nbsp;&nbsp; <i className={'fa fa-lock'} />
                    </Button>
                  </div>
                </CardHeader>
                <p className={classes.divider}>{dico.login.fillAllBlanks}</p>
                <CardBody>
                  <KMCustomInput
                    labelText={(this.state.forms.firstName.errorText === '') ? this.state.forms.firstName.label : this.state.forms.firstName.errorText}
                    error={this.state.forms.firstName.errorText !== ''}
                    success={this.state.forms.firstName.success}
                    id={this.state.forms.firstName.id}
                    handleMaterialChange={this.handleInputChangeMaterial}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: 'text',
                      endAdornment: (
                        <InputAdornment position="end">
                          <PeopleIcon className={classes.inputIconsColor} />
                        </InputAdornment>
                      )
                    }}
                  />
                  <KMCustomInput
                    labelText={(this.state.forms.lastName.errorText === '') ? this.state.forms.lastName.label : this.state.forms.lastName.errorText }
                    error={this.state.forms.lastName.errorText !== ''}
                    success={this.state.forms.lastName.success}
                    id={this.state.forms.lastName.id}
                    handleMaterialChange={this.handleInputChangeMaterial}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: 'text',
                      endAdornment: (
                        <InputAdornment position="end">
                          <PeopleIcon className={classes.inputIconsColor} />
                        </InputAdornment>
                      )
                    }}
                  />
                  
                  <KMEstablishment
                    labelText={(this.state.forms.establishmentId.errorText === '') ? this.state.forms.establishmentId.label : this.state.forms.establishmentId.errorText }
                    error={this.state.forms.establishmentId.errorText !== ''}
                    success={this.state.forms.establishmentId.success}
                    id={this.state.forms.establishmentId.id}
                    handleMaterialChange={this.handleSelectChangeMaterial}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: 'text',
                      name: this.state.forms.establishmentId.id,
                      endAdornment: (
                        <InputAdornment position="end">
                          <SchoolIcon className={classes.inputIconsColor} />
                        </InputAdornment>
                      )
                    }}
                  />
                  
                  <KMCustomInput
                    labelText={(this.state.forms.email.errorText === '') ? this.state.forms.email.label : this.state.forms.email.errorText }
                    error={this.state.forms.email.errorText !== ''}
                    success={this.state.forms.email.success}
                    id={this.state.forms.email.id}
                    handleMaterialChange={this.handleInputChangeMaterial}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: 'email',
                      endAdornment: (
                        <InputAdornment position="end">
                          <EmailIcon className={classes.inputIconsColor} />
                        </InputAdornment>
                      )
                    }}
                  />
                  <KMCustomInput
                    labelText={(this.state.forms.password.errorText === '') ? this.state.forms.password.label : this.state.forms.password.errorText }
                    error={this.state.forms.password.errorText !== ''}
                    success={this.state.forms.password.success}
                    handleMaterialChange={this.handleInputChangeMaterial}
                    id={this.state.forms.password.id}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: 'password',
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputIconsColor + ' fa fa-lock'}>

                          </Icon>
                        </InputAdornment>
                      )
                    }}
                  />
                  {this.state.forms.password.errorText !== '' &&
                    <div>{dico.login.error.passwordStrength}</div>
                  }

                </CardBody>
                <CardFooter className={classes.cardFooter}>
                  <Button simple color="primary" size="lg" onClick={this.handleRegister}>
                    {dico.login.registerAction}
                  </Button>

                </CardFooter>
              </form>
            </Card>
          </GridItem>
        </GridContainer>
      </div>

    )
  }
}

export default withStyles(registerFormStyle)(RegisterForm)
