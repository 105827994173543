import React from 'react'

import workInProgressImg from 'assets/img/workInProgress.png'
import { withStyles } from '@material-ui/core/styles'
import { Grid} from '@material-ui/core/'
import { moment} from 'moment'

import styles from './styles'

const WorkInProgress = ({
    classes,
    ...props
}) => (

        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
        >
            <Grid item>
                <img src={workInProgressImg} />
            </Grid>
            <Grid item>
                <div className={classes.keepapi}>
                    La plateforme est en maintenance (v2.0.0) ce jeudi 23 mai pour quelques heures ...
                    <br/>
                    Merci de votre compréhension.
                    <br/>
                    <b className={classes.golden}>L'équipe {'{'} Keep : Api {'}'}</b>

                </div>
            </Grid>

        </Grid>
    )


export default withStyles(styles)(WorkInProgress)
