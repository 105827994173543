import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider,  DatePicker } from 'material-ui-pickers';
import moment from 'moment'
import 'moment/min/locales'
moment.locale('fr')
const styles = {
};

class KDAEDatePicker extends React.Component {
  state = {
    selectedDate: this.props.initialValue || null,
  };

  handleDateChange = (date,event) => {
    this.setState({ selectedDate: date });
    if (this.props.handleInputChange)
      this.props.handleInputChange(date)
  };

  render() {
    const { selectedDate } = this.state;
    const { value, defaultCountry, onChange, classes, datas, ...onlyOtherProps } = this.props;

    return (
      <MuiPickersUtilsProvider utils={MomentUtils} locale={'fr'} moment={moment}>
        <DatePicker
          // margin="normal"
          value={selectedDate}
          onChange={this.handleDateChange}
          disableFuture={true}
          {...onlyOtherProps}
        />
      </MuiPickersUtilsProvider>
    );
  }
}

KDAEDatePicker.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(KDAEDatePicker);