import React, { Component, Fragment } from 'react'
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// @material-ui/core components
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import Select from '@material-ui/core/Select';
import customInputStyle from "assets/jss/material-kit-react/components/customInputStyle.jsx";
import config from '../../config'
import superagent from 'superagent'
class KMEstablishment extends Component {
  constructor(props) {
    super(props)
    let initialName = this.props.behaviors.name
    let initialValue = ('store' in this.props) ? this.props.store[this.props.behaviors.name] : this.props.initialValue
    this.initialName = initialName
    this.state = {
      datas: [],
      behaviors: {
        className: this.props.behaviors.className,
        errorTextMessage: this.props.behaviors.errorTextMessage,
        pattern: this.props.behaviors.pattern,
        hintText: this.props.behaviors.hintText,
        label: this.props.behaviors.label,
        name: initialName,
        success: false,
        placeholder: this.props.behaviors.placeholder,
        noCheck: this.props.behaviors.noCheck || false,
        required: this.props.behaviors.required || false
      },
      errorText: '',
      establishmentId: this.props.forceValue || initialValue || '',
      establishmentIds: []
    }
  }
  componentDidMount() {
    this.fetchEstablishment()
  }
  fetchEstablishment = async () => {
    let apiClientUrl = config.front.establishment.url
    apiClientUrl += '?sort=establishmentLabel_asc'
    let apiResponseNetwork
    try {
      apiResponseNetwork = await superagent
        .get(apiClientUrl)
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
        .set('X-JWT', window.localStorage.getItem('JWT'))
        .withCredentials()
    } catch (error) { return }
    let responseKeepapi = apiResponseNetwork.body
    if (responseKeepapi.code !== 'ok') return

    //  ajouter valeur par default 0
    let self = this
    const defaultObject = {
      establishmentId: 0,
      establishmentFullLabel: self.state.behaviors.placeholder,
      establishmentLabel: self.state.behaviors.placeholder,
      establishmentLogo: "",
      establishmentLogoSmall: ""
    }
    responseKeepapi.data.unshift(defaultObject)
    // END ajouter valeur par default 0

    this.setState({ establishmentIds: responseKeepapi.data })
    if (responseKeepapi.data.length > 0) {
      this.setState({ establishmentId: responseKeepapi.data[0].establishmentId})
      if (this.props.updateStore)
        this.props.updateStore("establishmentId", responseKeepapi.data[0].establishmentId);
      if (this.props.handleInputChange)
        this.props.handleInputChange(responseKeepapi.data[0].establishmentId)
    }
  }
  handleChange = (event, index, value) => {
    this.setState({ establishmentId: event.target.value });
    if (this.props.updateStore)
      this.props.updateStore("establishmentId", event.target.value);
    if (this.props.handleInputChange)
      this.props.handleInputChange(event.target.value)
  }
  moustache = () => {
    let rowsMenuItems
    let self = this
    rowsMenuItems = this.state.establishmentIds.map((data, position) => {
      if (self.state.establishmentId === data.establishmentId) {
        return (<Fragment>
          {data.establishmentLogo !== '' &&
          <img width="50" alt={data.establishmentLabel} src={data.establishmentLogo} />
          }
          &nbsp; {data.establishmentLabel}
        </Fragment>)
      }
    })
    return rowsMenuItems
  }
  render() {
    const {
      classes,
      formControlProps,
      labelText,
      id,
      labelProps,
      inputProps,
      error,
      white,
      inputRootCustomClasses,
      success, store, updateStore, focusKey, kmMultiple, behaviors, handleAllInputChangeForm, forceValue, fullwidth,
      ...onlyOtherProps
    } = this.props;

    const rowsMenuItems = this.state.establishmentIds.map(
      establishmentId => {
        return (
          <MenuItem disabled={establishmentId.establishmentId === 0} value={establishmentId.establishmentId}>
            <ListItemAvatar >
              {establishmentId.establishmentLogo !== '' &&
              <img alt={establishmentId.establishmentFullLabel} width="50" src={establishmentId.establishmentLogo} />
              }
            </ListItemAvatar>
            <ListItemText classes={{ primary: classes.primary }} inset primary={establishmentId.establishmentFullLabel} />
          </MenuItem>
        )
      }
    )
    return (
      <FormControl error={error} fullwidth={fullwidth} className={classes.formControl} {...onlyOtherProps}>
        <InputLabel htmlFor={this.state.behaviors.id}>{this.state.behaviors.label}</InputLabel>
        <Select
          {...inputProps}
          autoWidth
          id={this.state.behaviors.id}
          placeholder={this.state.behaviors.placeholder}
          renderValue={this.moustache}
          name={this.initialName}
          value={this.state.establishmentId}
          onChange={this.handleChange}
          onBlur={this.handleChange}
          className={classes.textField}
          margin="normal"
          // errortext={this.state.errorText}
          // error={this.state.errorText !== '' ? true : false}
          // required={this.state.behaviors.required}
          helperText={this.state.behaviors.helperText}
          {...onlyOtherProps}
        >
          {rowsMenuItems}
        </Select>
        {!!error === true &&
        <FormHelperText>{this.state.behaviors.errorTextMessage}</FormHelperText>
        }
      </FormControl>
    );
  }
}

KMEstablishment.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(customInputStyle)(KMEstablishment);
