import React, { useState,useEffect } from 'react'
import _ from 'lodash'

import { useNavigate,useLocation } from 'react-router-dom'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

// core components
import Header from 'components/KMLogin/Header/Header.jsx'
import HeaderLinks from "../../components/Header/HeaderLinks.jsx";
import Footer from 'components/KMLogin/Footer/Footer.jsx'

import KMMaterialWaiting from 'components/KMLogin/KMMaterial/KMMaterialWaiting'

import loginPageStyle from '../../assets/jss/material-kit-react/views/loginPage.jsx'

import image from 'assets/img/shutterstock_431020450.jpg'
import RegisterForm from './RegisterForm'
import ResetPasswordForm from './ResetPasswordForm'

import LoginForm from './LoginForm'



const LoginPage = (props) => {
  let navigate = useNavigate()
  let location = useLocation()
  let [viewMode, setViewMode] = useState('login')
  let [components, setComponents] = useState({
    KMMaterialWaiting: {
      displayWaiting: false
    }
  })
  let [token, setToken] = useState('')

  const displayWaitingOn = (event) => {
    const _components = _.extend(components)
    _components.KMMaterialWaiting.displayWaiting = true
    setComponents(_components)
  }
  const displayWaitingOff = (event) => {
    const _components = _.extend(components)
    _components.KMMaterialWaiting.displayWaiting = false
    setComponents(_components)
  }
  const handleRegisterView = (event) => {

    if (location.pathname === '/reset/') {
      navigate.push('/')
    }
    setViewMode('register')
  }
  const handleLoginView = (event) => {
    if (location.pathname === '/reset/') {
      navigate.push('/')
    }
    setViewMode('login')
  }
  // const handleResetPasswordView = (event) => setViewMode('resetPassword')

  useEffect(() => {

    if (location.pathname === '/reset/') {
      setToken(location.search)
      setViewMode('resetPassword')
    }
  }, [])


  const { classes, ...rest } = props
  return (
    <div className="LoginPage">
      <Header
        absolute
        color="transparent"
        brandImage={require('assets/img/header-logo-catho.png')}
        brand=""
        rightLinks={ <HeaderLinks isModal={true}  />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: 'url(' + image + ')',
          backgroundSize: 'cover',
          backgroundPosition: 'top center'
        }}
      >
     {viewMode === 'resetPassword' &&
          <ResetPasswordForm
            token={token.replace(/\?/gi,'')}
            openPrivateRoute={props.openPrivateRoute}
            displayWaitingOn={displayWaitingOn}
            displayWaitingOff={displayWaitingOff}
            handleSwitchView={handleLoginView}
          />
        }
        {/* {viewMode === 'register' &&
        <RegisterForm
          openPrivateRoute={props.openPrivateRoute}
          displayWaitingOn={displayWaitingOn}
          displayWaitingOff={displayWaitingOff}
          handleSwitchView={handleLoginView}
        />

        } */}
        {viewMode === 'login' &&
          <LoginForm
            openPrivateRoute={props.openPrivateRoute}
            displayWaitingOn={displayWaitingOn}
            displayWaitingOff={displayWaitingOff}
            handleSwitchView={handleRegisterView}
          />
        }

        <Footer whiteFont />
      </div>

      {/* <KMMaterialSnackBar
        type="Snackbar"
        open={open}
        message={message}
        autoHideDuration={autoHideDuration}
        action={action}
        onClose={onClose}
      /> */}

      <KMMaterialWaiting

        display={components.KMMaterialWaiting.displayWaiting}

      />
    </div>
  )
}





export default withStyles(loginPageStyle)(LoginPage)
