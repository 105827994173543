// src/ui/theme/index.js
import { purple } from '@material-ui/core/colors'

import { createTheme } from '@material-ui/core/styles';

export default createTheme({
  palette: {
    primary: purple,
    secondary: purple,
  },
});


