import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import classNames from "classnames";

import config from 'config'
import superagent from 'superagent'
const styles = theme => ({
  progress: {
    margin: theme.spacing( 2)
  },

  container: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,

    background: 'rgba(0,0,0,0.3)'

  },
  flex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh'

  },
  refresh: {
    width: '142',
    height: '142'
  }
})
class KMMaterialWaiting extends React.Component {
  constructor(props) {
    super(props)
    this.timer1 = null

  }

  componentDidMount() {
    if (this.props.disconnect) {
      this.timer1 = setTimeout(() => {
        const oauthLogoutUrl = config.oauth.logout.url
        superagent
          .get(oauthLogoutUrl)
          .set('Content-Type', 'application/json')
          .set('Accept', 'application/json')
          .set('X-JWT', window.localStorage.getItem('JWT'))
          .withCredentials()

        // let responseKeepapi = apiResponseNetwork.body
        // if (responseKeepapi.code !== 'ok') return

        try {
          function removeCookie(name, path, domain) {
            let str = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;${path ? " path=" + path + ";" : ""}${domain ? " domain=" + domain + ";" : ""}`
            document.cookie = str
          }
          removeCookie("kmSession", '/', `.${config.mainDomainName}`);

        } catch (e) { }
        window.sessionStorage.clear()
        window.localStorage.clear()
        window.location.href = `https://${config.mainDomainName}`
      }, 15000)
    }
  }
  componentWillUnmount() {
    if (this.props.disconnect) {
      clearTimeout(this.timer1)
    }
  }

  render() {
    const { classes } = this.props
    const style = {
      container: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,

        background: 'rgba(0,0,0,0.3)'

      },
      flex: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'


      },
      refresh: {
        width: "142px",
        height: "142px",
      }
    };
    if (this.props.display === true) {
      const circularProgressClasses = classNames({
        [classes.progress]: true,
        [style.refresh]: true
      })
      return (
        <div className={style.container}>
          <div className={style.flex}>
            <CircularProgress className={circularProgressClasses} color="secondary" />
          </div>
        </div>

      )
    } else return null
  }
}

// KMMaterialWaiting.propTypes = {
//   classes: PropTypes.object.isRequired,
// };

export default withStyles(styles)(KMMaterialWaiting)
