import React from 'react';
import classNames from "classnames";
import PropTypes from 'prop-types';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import customInputStyle from "assets/jss/material-kit-react/components/customInputStyle.jsx";
import config from 'config'
import superagent from 'superagent'
class KMEstablishment extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      establishmentId: '',
      establishmentIds: []
    }
  }

  componentDidMount() {
    this.fetchEstablishment()
  }
  async fetchEstablishment() {
    let apiClientUrl = config.front.establishment.url
    apiClientUrl += '?sort=establishmentLabel_asc'
    let apiResponseNetwork
    try {
      apiResponseNetwork = await superagent
        .get(apiClientUrl)
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
        // .set('X-JWT', window.localStorage.getItem('JWT'))
        .withCredentials()
    } catch (error) { return }
    let responseKeepapi = apiResponseNetwork.body
    if (responseKeepapi.code !== 'ok') return
    this.setState({ establishmentIds: responseKeepapi.data })
    if(responseKeepapi.data.length > 0){
      this.setState({ establishmentId: responseKeepapi.data[0].establishmentId })
      this.props.handleMaterialChange(this.props.id, responseKeepapi.data[0].establishmentId);

    }
  }
  handleChange = (event, index, value) => {
    this.setState({ establishmentId: event.target.value });
    this.props.handleMaterialChange(this.props.id, event.target.value );
  }
  render() {
    const {
      classes,
      formControlProps,
      labelText,
      id,
      labelProps,
      inputProps,
      error,
      white,
      inputRootCustomClasses,
      success
    } = this.props;
    const labelClasses = classNames({
      [" " + classes.labelRootError]: error,
      [" " + classes.labelRootSuccess]: success && !error
    });
    const underlineClasses = classNames({
      [classes.underlineError]: error,
      [classes.underlineSuccess]: success && !error,
      [classes.underline]: true,
      [classes.whiteUnderline]: white
    });
    const marginTop = classNames({
      [inputRootCustomClasses]: inputRootCustomClasses !== undefined
    });
    const inputClasses = classNames({
      [classes.input]: true,
      [classes.whiteInput]: white
    });
    var formControlClasses;
    if (formControlProps !== undefined) {
      formControlClasses = classNames(
        formControlProps.className,
        classes.formControl
      );
    } else {
      formControlClasses = classes.formControl;
    }
    const rowsMenuItems = this.state.establishmentIds.map(
      (establishmentId,position) => {
        return (
          <MenuItem className={classes.menuItem} key={"MenuItem_"+position} value={establishmentId.establishmentId}>
            <ListItemAvatar >
              <img alt={establishmentId.establishmentFullLabel} src={establishmentId.establishmentLogo} width="50" style={{margin:"20px"}}/>
            </ListItemAvatar>
            <ListItemText classes={{ primary: classes.primary }} inset primary={establishmentId.establishmentFullLabel} />
          </MenuItem>
        )
      }
    )
    return (
      <FormControl {...formControlProps} className={formControlClasses}>
        {labelText !== undefined ? (
          <InputLabel
            className={classes.labelRoot + " " + labelClasses}
            htmlFor={id}
            {...labelProps}
          >
            {labelText}
          </InputLabel>
        ) : null}
        <Select
          onChange={this.handleChange}
          classes={{
            input: inputClasses,
            root: marginTop,
            disabled: classes.disabled,

          }}
          id={id}
          input={<Input classes={{
            underline: underlineClasses
          }} />}

          {...inputProps}
          value={this.state.establishmentId}
        >
          {rowsMenuItems}
        </Select>
      </FormControl>

    );
  }
}

KMEstablishment.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(customInputStyle)(KMEstablishment);