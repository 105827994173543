import lodash from 'lodash'
import { withLangStore } from "stores/KLangProvider.js"
import superagent from 'superagent'
import config from 'config'
import { withSnackbar } from 'notistack'
import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { InputAdornment, IconButton } from '@material-ui/core'
import { LockRounded, VisibilityRounded, EmailRounded } from '@material-ui/icons'
import GridContainer from 'components/KMLogin/Grid/GridContainer.jsx'
import GridItem from 'components/KMLogin/Grid/GridItem.jsx'
import Button from 'components/KMLogin/CustomButtons/Button.jsx'
import Card from 'components/KMLogin/Card/Card.jsx'
import CardBody from 'components/KMLogin/Card/CardBody.jsx'
import CardHeader from 'components/KMLogin/Card/CardHeader.jsx'
import CardFooter from 'components/KMLogin/Card/CardFooter.jsx'
import KMCustomInput from 'components/KMLogin/KMCustomInput/KMCustomInput.jsx'
import loginFormStyle from 'assets/jss/material-kit-react/views/loginPage.jsx'
import KMPopupForgotPassword from './KMPopupForgotPassword'
import LegalNotice from '../KDAE_CareFolderView/LegalNotice'
import MicrosoftButton from 'components/SocialNetworkButton/MicrosoftButton'
import GoogleButton from 'components/SocialNetworkButton/GoogleButton'
// import LinkedInButton from 'components/SocialNetworkButton/LinkedInButton'
import DividerWithText from 'components/Divider/DividerWithText'

class LoginForm extends React.Component {
  constructor(props) {
    super(props)
    this._isMounted = false
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      flagPasswordVisible: false,
      openForgotPasswordPopup: false,
      cardAnimaton: 'cardHidden',
      viewMode: 'login',
      components: {
        KMMaterialWaiting: {
          displayWaiting: false
        }
      },
      forms: {
        email: {
          errorText: '',
          errorTextMessage: props.langStore.loginForm.error.email,
          pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
          hintText: props.langStore.loginForm.email,
          value: '',
          label: props.langStore.loginForm.email,
          id: 'email',
          success: false
        },
        password: {
          errorText: '',
          errorTextMessage: props.langStore.loginForm.error.password,
          pattern: /.{5,}/,
          hintText: props.langStore.loginForm.password,
          value: '',
          label: props.langStore.loginForm.password,
          id: 'password',
          success: false
        }
      }
    }

    this.handleInputChangeMaterial = this.handleInputChangeMaterial.bind(this)
  }
  handleFlagPasswordVisible = () => this.setState({ flagPasswordVisible: !this.state.flagPasswordVisible })

  handleInputChangeMaterial(event) {
    const target = event.target
    let id = event.target.id
    let value = target.type === 'checkbox' ? target.checked : target.value
    let forms = lodash.extend(this.state.forms)
    // prepare value to set
    forms[id].value = value
    this.setState(prevState => ({
      forms: forms
    }))
  }
  customCheckField(field) {
    let { errorText, error, name } = this.checkField([field])
    const forms = lodash.extend(this.state.forms)
    forms[name].errorText = errorText
    forms[name].success = !error
    this.setState({ forms: forms })
    return !error
  }
  checkField(fields) {
    let error = false
    let errorText = ''
    let value = ''
    const forms = lodash.extend(this.state.forms)
    let name

    fields.map((currentName) => {
      if (!!error === true) { return }
      name = currentName
      if (
        forms[currentName].hasOwnProperty('pattern') &&
        forms[currentName].hasOwnProperty('errorText') &&
        forms[currentName].hasOwnProperty('errorTextMessage') &&
        forms[currentName].errorTextMessage !== ''
      ) {
        value = forms[currentName].value
        if (!value.match(forms[currentName].pattern)) {
          error = true
          errorText = forms[currentName].errorTextMessage
        } else {
          errorText = ''
        }
      }
      return currentName
    })
    return { error, errorText, name }
  }
  handleLogInWithSocialNetwork = async (response, oauthLoginUrl) => {
    const components = lodash.extend(this.state.components)
    components.KMMaterialWaiting.displayWaiting = true
    this.setState({ components: components })
    let error = true
    error = error & response.email !== '' & !!response.email.match(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,6}$/i)
    error = error & response.externalId !== ''
    if (!!error !== false) {
      function removeCookie(name, path, domain) {
        let str = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;${path ? " path=" + path + ";" : ""}${domain ? " domain=" + domain + ";" : ""}`
        document.cookie = str
      }
      removeCookie("kmSession", '/', `.${config.mainDomainName}`)
      window.localStorage.removeItem('JWT')
      let loggedIn = false
      let messageDetail = ''
      try {
        let env = await fetch(oauthLoginUrl, {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          credentials: 'include',
          method: 'POST',
          body: JSON.stringify({ email: response.email, password: response.externalId })
        })
        let { code, data } = await env.json()
        if (code === 'ok') {
          if (data.auth === true && data.token !== '') {
            window.localStorage.setItem('JWT', data.token)
            loggedIn = true
          }
        } else {
          if (data && data.message && Array.isArray(data.message) && data.message.length > 0 && data.message[0].startsWith("Votre compte"))
            messageDetail = data.message[0]
        }
      } catch (error) {
        loggedIn = false
      }
      if (loggedIn === true) {
        let apirUrl = config.admin.autolog.url
        apirUrl = apirUrl.replace('___TOKEN___', window.localStorage.getItem('JWT'))
        window.location.href = apirUrl
      } else {
        let message = this.props.langStore.loginForm.error.oauth.not_found
        if (messageDetail !== '') message = messageDetail
        let variant = 'info'
        this.props.enqueueSnackbar(message, { variant })
      }
    } else {
      let message = this.props.langStore.loginForm.error.oauth.not_found
      let variant = 'info'
      this.props.enqueueSnackbar(message, { variant })
    }
    components.KMMaterialWaiting.displayWaiting = false
    this.setState({ components: components })
  }
  handleLogInWithGoogle = async (response) => {
    const oauthLoginUrl = config.oauth.loginWithGoogle.url
    return this.handleLogInWithSocialNetwork(response, oauthLoginUrl)
  }
  handleLogInWithMicrosoft = async (response) => {
    const oauthLoginUrl = config.oauth.loginWithMicrosoft.url
    return this.handleLogInWithSocialNetwork(response, oauthLoginUrl)
  }
  handleLogIn = async (event) => {
    if (event) event.preventDefault()
    if (window.sessionStorage.getItem('stopRediect') === true) {
      debugger
    }
    const components = lodash.extend(this.state.components)
    components.KMMaterialWaiting.displayWaiting = true
    this.setState({ components: components })
    let error = true
    error = error & this.customCheckField('email')
    error = error & this.customCheckField('password')
    if (!!error !== false) {
      function removeCookie(name, path, domain) {
        let str = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;${path ? " path=" + path + ";" : ""}${domain ? " domain=" + domain + ";" : ""}`
        document.cookie = str
      }
      removeCookie("kmSession", '/', `.${config.mainDomainName}`)
      window.localStorage.removeItem('JWT')
      const oauthLoginUrl = config.oauth.login.url
      let loggedIn = false
      try {
        let env = await fetch(oauthLoginUrl, {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-JWT': window.localStorage.getItem('JWT')
          },
          credentials: 'include',
          method: 'POST',
          body: JSON.stringify({ email: this.state.forms.email.value, password: this.state.forms.password.value })
        })
        let { code, data } = await env.json()
        if (code === 'ok') {
          if (data.auth === true && data.token !== '') {
            window.localStorage.setItem('JWT', data.token)
            loggedIn = true
          }
        }
      } catch (error) {
        loggedIn = false
      }
      if (loggedIn === true) {
        if (window.sessionStorage.getItem('stopRediect') === true) {
          console.log("loggedIn ", true)
        }
        let apirUrl = config.admin.autolog.url
        apirUrl = apirUrl.replace('___TOKEN___', window.localStorage.getItem('JWT'))
        if (window.sessionStorage.getItem('stopRediect') === true) {
          console.log("redirectTo ", apirUrl)
          debugger
        }

        window.location.href = apirUrl
      } else {
        if (window.sessionStorage.getItem('stopRediect') === true) {
          console.log("loggedIn ", false)
        }
        let message = this.props.langStore.loginForm.error.oauth.not_found
        let variant = 'info'
        this.props.enqueueSnackbar(message, { variant })
      }
    }
    components.KMMaterialWaiting.displayWaiting = false
    this.setState({ components: components })
  }
  componentDidMount() {
    this._isMounted = true
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function () {
        if (this._isMounted) {
          this.setState({ cardAnimaton: '' })
        }
      }.bind(this),
      220
    )
  }
  componentWillUnmount() {
    this._isMounted = false
  }
  render() {
    const { classes, langStore } = this.props
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={4}>
            <Card className={classes[this.state.cardAnimaton]}>
              <form className={classes.form}
                onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                    // Do code here
                    ev.preventDefault()
                    this.handleLogIn()
                  }
                }}>
                <CardHeader color="primary" className={classes.cardHeader}>
                  <h4>{langStore.loginForm.title2}</h4>
                </CardHeader>

                <p className={classes.divider}>
                  <LegalNotice typeTexteDPO={2} /></p>
                <MicrosoftButton
                  clientId={config.socialNetwork.microsoft.clientId}
                  fullWidth
                  text="Connexion avec Microsoft"
                  onClick={this.handleLogInWithMicrosoft}
                />
                <GoogleButton
                  clientId={config.socialNetwork.google.clientId}
                  fullWidth
                  text="Connexion avec Google"
                  onClick={this.handleLogInWithGoogle}
                />
                {/* <LinkedInButton
                  clientId={config.socialNetwork.linkedIn.clientId}
                  fullWidth
                  text="Connexion avec LinkedIn"
                  onClick={this.handleLogInWithLinkedIn}
                /> */}

                <p className={classes.divider}>
                  <DividerWithText> {langStore.conjunctionOr}</DividerWithText>

                </p>
                <CardBody>
                  <KMCustomInput
                    labelText={(this.state.forms.email.errorText === '') ? this.state.forms.email.label : this.state.forms.email.errorText}
                    error={this.state.forms.email.errorText !== ''}
                    success={this.state.forms.email.success}
                    id={this.state.forms.email.id}
                    handleMaterialChange={this.handleInputChangeMaterial}
                    formControlProps={{
                      autoFocus: true,
                      fullWidth: true
                    }}
                    inputProps={{
                      type: 'email',
                      endAdornment: (
                        <InputAdornment position="end">
                          <EmailRounded className={classes.inputIconsColor} />
                        </InputAdornment>
                      )
                    }}
                  />
                  <KMCustomInput
                    labelText={(this.state.forms.password.errorText === '') ? this.state.forms.password.label : this.state.forms.password.errorText}
                    error={this.state.forms.password.errorText !== ''}
                    success={this.state.forms.password.success}
                    id={this.state.forms.password.id}
                    handleMaterialChange={this.handleInputChangeMaterial}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: !!this.state.flagPasswordVisible === true ? 'text' : 'password',
                      endAdornment: (
                        <InputAdornment position="end" onClick={this.handleFlagPasswordVisible} >
                          <IconButton style={{ padding: 0 }}>
                            {!!this.state.flagPasswordVisible === true ? <LockRounded /> : <VisibilityRounded />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </CardBody>
                <CardFooter className={classes.cardFooter}>
                  <Button simple color="primary" size="lg" onClick={this.handleLogIn}>
                    {langStore.loginForm.loginAction}
                  </Button>
                </CardFooter>
                <CardFooter className={classes.cardFooter}>
                  <KMPopupForgotPassword
                    handleOK={this.handleOK}
                    handleClose={this.handleCloseForgotPasswordPopup}
                    open={this.state.openForgotPasswordPopup} />
                  <Button simple color="primary" size="sm" onClick={this.handleOpenForgotPasswordPopup}>{langStore.loginForm.forgotPassword}</Button>
                </CardFooter>
              </form>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    )
  }
  handleOpenForgotPasswordPopup = () => this.setState({ openForgotPasswordPopup: true })
  handleCloseForgotPasswordPopup = () => this.setState({ openForgotPasswordPopup: false })
  handleOK = async (userEmail) => {
    this.setState({ openForgotPasswordPopup: false })
    if (userEmail === '') {
      let message = `Email vide`
      let variant = 'warning'
      this.props.enqueueSnackbar(message, { variant })
      return
    }
    let apiResponseNetwork
    let apiUrl = config.webhook.forgotPasswordUser.url
    try {
      apiResponseNetwork = await superagent
        .post(apiUrl)
        .send({ userEmail })
        .set('X-JWT', window.localStorage.getItem('JWT'))
        .withCredentials()
    } catch (error) {
      let message = `erreur réseau X180520191825 ${error}`
      let variant = 'error'
      this.props.enqueueSnackbar(message, { variant })
      return
    }
    let responseKeepapi = apiResponseNetwork.body
    let message, variant
    if (responseKeepapi.code !== 'ok') {
      message = `une erreur est survenue`
      variant = 'error'
    } else {
      message = `Nous vous avons transmis par mail un lien qui vous permettra de réinitialiser votre mot de passe`
      variant = 'success'
    }
    this.props.enqueueSnackbar(message, { variant })
  }
}

export default withStyles(loginFormStyle)(withSnackbar(withLangStore(LoginForm)))
