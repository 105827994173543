import { withLangStore } from "../../stores/KLangProvider.js";
import React from 'react';
import classNames from "classnames";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// import AddComentIcon from '@material-ui/icons/AddComment';
// import ThumbDownIcon from '@material-ui/icons/ThumbDownRounded';
import ThumbUpIcon from '@material-ui/icons/ThumbUpRounded';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
// import customInputStyle from "assets/jss/material-dashboard-react/components/customInputStyle.jsx";

// const styles = theme => ({
//     button: {
//         margin: theme.spacing(),
//     },
//     leftIcon: {
//         marginRight: theme.spacing(),
//     },
//     rightIcon: {
//         marginLeft: theme.spacing(1),
//     },
// });
class KMPopupForgotPassword extends React.Component {
    state = {
        email: ''
    };

    // set state forms
    handleInputChangeForm = (event) => {
        const target = event.target
        let value = target.type === 'checkbox' ? target.checked : target.value
        this.setState({ email: value })
    }
    handleOK = () => {
        this.props.handleOK(this.state.email);
        this.setState({ email: '' })
    };
    render() {
        let myWidth = this.props.width === 'sm' || this.props.width === 'xs' ? 'smartphone' : 'large'
        if(!!this.props.open !== true) return null
        const {
            classes,
            labelText,
            error,
            success,
            langStore
          } = this.props;
        
          const underlineClasses = classNames({
            [classes.underlineError]: error,
            [classes.underlineSuccess]: success && !error,
            [classes.underline]: true
          });
          const marginTop = classNames({
            [classes.marginTop]: labelText === undefined
          });

        return (
            <Dialog
                fullWidth={true}
                open={this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    {langStore.KMPopupForgotPassword.expireTitle.text}</DialogTitle>
                <DialogContent>
                <DialogContentText>
<b>{langStore.KMPopupForgotPassword.expireSubTitle.text}</b>
{langStore.KMPopupForgotPassword.expireDescription.text}
</DialogContentText>
                <TextField
                        multiline={false}
                        autoFocus={myWidth === 'smartphone' ? false : true}
                        margin="dense"
                        label={langStore.KMPopupForgotPassword.userEmailLabel.text}
                        type="text"
                        fullWidth
                        value={this.state.email}
                        onChange={this.handleInputChangeForm}
                        classes={{
                            root: marginTop,
                            disabled: classes.disabled,
                            underline: underlineClasses
                          }}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.handleClose} color="primary">
                    {langStore.KMPopupForgotPassword.closePopin.text}
            </Button>
                    
                    <Button
                        onClick={this.handleOK}

                        variant="contained" color="secondary" className={classes.button}>

{langStore.KMPopupForgotPassword.resendMessaegAction.text}
                        <ThumbUpIcon className={classes.rightIcon} />
            </Button>
                </DialogActions>
            </Dialog>
        )
    };
}
export default withWidth()(withStyles()(withLangStore(KMPopupForgotPassword)))

