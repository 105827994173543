import React, {useState} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import MicrosoftLogin from 'react-microsoft-login'
import { withStyles } from '@material-ui/core/styles'

import imageSrc from 'assets/img/social/Microsoft_logo.svg'
import Button from '.'
import styles from './styles'


const MicrosoftButton = ({
                           text,
                           classes,
                           onClick,
                           clientId,
                           className,
                           redirectUri,
                           ...props
                         }) => {

  const [clicked, setClicked] = useState(false);
  const resultMicrosoft = (error, result) => {
    if (!clicked) {
      setClicked(true);

      if (error || !result || !result.account || !result.accessToken) {
        return null
      }
      const {accountIdentifier, userName} = result.account
      const accessToken = result.accessToken
      return onClick && onClick({
        externalProvider: 'microsoft',
        externalId: accountIdentifier,
        externalAccessToken: accessToken,
        email: userName,
        profile: {
          imageUrl: null,
          lastName: null,
          firstName: null,
        },
      })
    }
  }
  const defaultRenderer =
      <Button
          variant="contained"
          className={classNames(classes.microsoft, classes.socialNetwork, className)}
          {...props}
      >
        <img src={imageSrc} alt={text} className={classes.socialNetworkIcon} />
        {text}
      </Button>
  const debug = true
  return (
      <MicrosoftLogin
          clientId={clientId}
          authCallback={ resultMicrosoft}
          children={defaultRenderer}
          redirectUri={redirectUri || null}
      />
  )
}


MicrosoftButton.propTypes = {
  clientId: PropTypes.string,
  text: PropTypes.string,
  render: PropTypes.func,
  onClick: PropTypes.func,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(MicrosoftButton)
