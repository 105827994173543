import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const styles = theme => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(3),
    },
    group: {
        margin: `${theme.spacing()}px 0`,
    },
});

class KDAERadio extends React.Component {
    state = {
        value: this.props.initialValue || '',
    };

    handleChange = event => {
        this.setState({ value: event.target.value });
        if (this.props.handleInputChange)
            this.props.handleInputChange( event.target.value)
    };

    render() {

        let rowsRadio = this.props.datas.map(data => <FormControlLabel control={<Radio />} {...data} ></FormControlLabel>)
        const { label, value, defaultCountry, onChange, classes, datas, ...onlyOtherProps } = this.props;

        return (
            <div className={classes.root}>
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel component="legend">{label}</FormLabel>
                    <RadioGroup
                        className={classes.group}
                        value={this.state.value}
                        onChange={this.handleChange}
                        {...onlyOtherProps}
                    >
                        {rowsRadio}

                    </RadioGroup>
                </FormControl>
            </div>
        );
    }
}

KDAERadio.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(KDAERadio);