import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import withWidth from '@material-ui/core/withWidth';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/CloseRounded';
// import headerLinksStyle from "../../assets/jss/material-kit-react/components/headerLinksStyle.jsx";
import { withLangStore } from "../../stores/KLangProvider.js";
import LoginPage from './LoginPage'
const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class LoginPageModal extends React.Component {

  render() {

    const { langStore, open, handleClose } = this.props;
    const fullScreen = this.props.width === 'sm' || this.props.width === 'xs' ? true : false

    let myWidth = this.props.width === 'sm' || this.props.width === 'xs' ? 'smartphone' : 'large'
    let closeImg = { cursor: 'pointer', zIndex: 10000 };
    return (
      <Fragment>
        {/* <Button
          color="transparent"
          className={classes.navLink}
          variant="outlined"
          color="primary" onClick={this.handleClickOpen}
        >
          <Input className={classes.icons} />
          {langStore.home.login.text}
        </Button> */}

        <Dialog
          fullScreen={fullScreen}
          maxWidth="lg"
          fullWidth={true}
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
          aria-describedby={langStore.home.login.ariaDescribedby}
          aria-labelledby={langStore.home.login.ariaLabelledby}
        >
          {myWidth === 'smartphone' &&
            <IconButton
              aria-label="close popin"
              onClick={this.props.handleClose} style={closeImg}>
              <CloseIcon />
            </IconButton>
          }
          {/* <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={this.handleClose} aria-label="Close">
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar> */}
          <LoginPage />

        </Dialog>
      </Fragment>
    );
  }

}

export default withWidth()(withStyles(styles)(withLangStore(LoginPageModal)))