import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
const styles = theme => ({
  root: {
    marginTop: theme.spacing(2),
  },
});
class KDAECheckbox extends React.Component {
  state = {
    checkedA: this.props.initialChecked
  };
  handleChange = name => event => {
    this.setState({ [name]: event.target.checked })
    if (this.props.handleInputChange)
      this.props.handleInputChange(event.target.checked)
  };
  render() {
    const { label, value, defaultCountry, onChange, classes, datas, ...onlyOtherProps } = this.props;
    return (
      <FormControl {...onlyOtherProps}>
      <FormControlLabel
      className={classes.root}
        control={
          <Checkbox
            checked={this.state.checkedA}
            onChange={this.handleChange('checkedA')}
            value="checkedA"
          />
        }
        label={label}
      />
      </FormControl>
    );
  }
}

KDAECheckbox.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(KDAECheckbox);