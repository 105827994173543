
import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
const dividerStyles = makeStyles((theme) => ({
    dividerWithText: {
        display: 'flex',
        alignItems: 'center',
    },
    'border': {
        borderBottom: '1px solid #ccc',
        width: '100%'
    },
    'content': {
        padding: '0 10px 0 10px'
    }
}));
const MyDivider = ({ children }) => {
    const classes = dividerStyles()

    return (
        <div className={classes.dividerWithText}>
            <div className={classes.border} />
            <span className={classes.content}>
                {children}
            </span>
            <div className={classes.border} />
        </div>
    );
}

export default MyDivider