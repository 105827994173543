import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withLangStore } from "../../stores/KLangProvider.js";

class LegalNotice extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            scroll: 'paper'
        }
    }


    handleClickOpen = scrollType => () => {
        this.setState({ open: true })
        this.setState({ open: scrollType })
    };

    handleClose = () => {
        this.setState({ open: false })
    }
    render() {
        const { langStore, typeTexteDPO } = this.props
        return (
            <div>
                <span style={{ cursor: 'pointer' }} onClick={this.handleClickOpen('paper')}>{langStore.legalNotice.title.text}</span>
                <Dialog
                    maxWidth={"md"}
                    open={this.state.open}
                    onClose={this.handleClose}
                    scroll={this.state.scroll}
                    aria-labelledby="scroll-dialog-title"
                >
                    <DialogTitle id="scroll-dialog-title">{langStore.legalNotice.title.text}</DialogTitle>
                    <DialogContent dividers={this.state.scroll === 'paper'}>
                        <DialogContentText>
                            {typeTexteDPO === 1 &&
                                <div
                                    dangerouslySetInnerHTML={{ __html: langStore.legalNotice.dpo1.text }}
                                >
                                </div>
                            }
                            {typeTexteDPO === 2 &&
                                <div
                                    dangerouslySetInnerHTML={{ __html: langStore.legalNotice.dpo2.text }}
                                >
                                </div>


                            }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Fermer
          </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}
export default withLangStore(LegalNotice)

