// import { getColumnWidth } from '../../helpers'


const styles = theme => ({
  root: {
    height: 40,
    padding: '0 20px',
    boxShadow: 'none',
    position: 'relative',

    '&:hover $hover': {
      opacity: 1,
    },
  },
  smallButton: {
    height: 30,
    lineHeight: '30px',
    padding: '0 10px',
  },
  buttonGray: {
    // color: theme.palette.common.black,
    transition: 'all 0.3s ease-in-out',
    backgroundImage: 'linear-gradient(to bottom, #fff, #cbcecf)',
  },
  fb: {
    color: 'white',
    // backgroundColor: `${theme.palette.network.facebook} !important`,
  },
  microsoft: {
    color: 'white',
    // backgroundColor: `${theme.palette.network.facebook} !important`,
  },
  socialNetwork: {
    padding: '0 20px',
    color: 'white',
    textAlign: 'center',
    marginTop: 10,
  },
  microsoft: {
    color: 'white',
    // backgroundColor: `${theme.palette.network.facebook} !important`,
  },

  socialNetworkIcon: {
    width: 16,
    height: 16,
    marginRight: 10,
  },
  fixedWidth: {
    paddingLeft: 0,
    paddingRight: 0,
    // minWidth: getColumnWidth(2),
  },
  icon: {
    marginLeft: 10,
  },

  white: {
    // borderColor: theme.palette.common.white,
    // color: theme.palette.common.white,
  },

  gray: {
    // background: `linear-gradient(${theme.palette.gray.main}, ${theme.palette.gray.dark})`,
  },

  gold: {
    background: 'linear-gradient(#ffd23f, #f0c002)',
  },

  black: {
    // color: theme.palette.common.white,
    background: 'linear-gradient(#5a555a, #46464b)',
  },
  darkGray: {
    // color: theme.palette.common.white,
    background: 'linear-gradient(to bottom, #5a555a, #24282a)',
  },

  lightGray: {
    color: '#5a555a',
    border: ' 1px solid #5a555a',
  },

  outlined: {
    '&$gray': {
      color: 'white',
      background: 'transparent',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'white',

      '&:hover': {
        borderColor: 'transparent !important',
        // color: theme.palette.common.black,
      },
    },
  },

  contained: {
    '&$white': {
      // color: theme.palette.common.black,
    },
  },

  content: {
    position: 'relative',
  },

  hover: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
    borderRadius: 3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    position: 'absolute',
    transition: 'opacity .2s ease-in-out',

    '&$gray': {
      // background: `linear-gradient(${theme.palette.gray.dark}, ${theme.palette.gray.main})`,
    },
    '&$primary': {
      // background: `linear-gradient(${theme.palette.primary.dark}, ${theme.palette.primary.main})`,
    },
    '&$secondary': {
      // background: `linear-gradient(${theme.palette.secondary.dark}, ${theme.palette.secondary.main})`,
    },
    '&$gold': {
      background: 'linear-gradient(#f0c002, #ffd23f)',
    },
    '&$black': {
      background: 'linear-gradient(#46464b, #5a555a)',
    },
  },

  primary: {},
  secondary: {},
})


export default styles
