module.exports = {
  'conjunctionOr': 'ou',
  'loginForm': {
    'title1': '',
    'title2': 'Connectez-vous',
    'fillAllBlanks': 'Remplissez les champs suivants',
    'subscribeNow': 'inscrivez-vous ici',
    'connectNow': 'connectez-vous ici',
    'titleSocials': 'Ou connectez-vous directement via',
    'socialsCgu': 'En vous connectant via votre compte de réseaux sociaux, vous déclarez accepter les conditions générales d"utilisations',
    'loginAction': 'Se connecter',
    'registerAction': `Faire une demande d'inscription`,
    'logout': 'Déconnexion',
    'loginOr': 'Login or',
    'or': 'ou',
    'signUp': 'Créer un compte',
    'rememberMe': 'Se souvenir de moi',
    'forgotPassword': 'Mot de passe oublié ?',
    'error': {
      'password': 'Le mot de passe est obligatoire',
      'passwordStrength': 'Le mot de passe doit contenir au moins 5 caractères avec au moins une minuscule + une majuscule + un chiffre + un caractère spécial. Par exemple %!@#$&*-.+~{[|^]}',
      'firstName': 'Le prénom est obligatoire',
      'lastName': 'Le nom est obligatoire',
      'etablissementId': 'Veuillez choisir un établissement',
      'establishmentId': 'Veuillez choisir un établissement',
      'email': 'email institutionnel (si vous ne disposez pas encore d’une adresse institutionnelle délivrée par votre établissement, vous pouvez temporairement utiliser votre adresse personnelle)',
      'oauth': {
        'access_denied': 'Vous avez refusé la connexion, donc nous ne pouvons pas vous identifier, veuillez l’accepter afin de vous connecter',
        'not_found': `Nous n'avons pas trouvé de correspondance dans notre base de données. Veuillez soit contacter l'administrateur si le problème persiste ou utiliser le lien « mot de passe oublié ».`,
        'notRegister': `Nous n'avons pas réussi à créer votre fiche. Veuillez réessayer plus tard ou contacter l'administrateur si le problème persiste.`
      }
    },
    firstName: `Votre prénom ...`,
    lastName: `Votre nom ...`,
    email: `Email ...`,
    password: `Mot de passe ...`,
    etablissementId: 'Votre établissement ...',
    establishmentId: 'Votre établissement/ votre service ...'
  },
  'KMPopupForgotPassword': {
    userEmailLabel: {
      text: `Entrez votre email`,
      speech: [

      ],
      ariaLabel: `Entrez votre email`
    },
    expireTitle: {
      text: `Faire expirer le password de l'utilisateur?`,
      speech: [

      ],
      ariaLabel: `Faire expirer le password de l'utilisateur?`
    },
    expireSubTitle: {
      text: `Mot de passe oublié ?`,
      speech: [

      ],
      ariaLabel: `Mot de passe oublié ?`
    }
    ,
    expireDescription: {
      text: `Entrez votre email pour nous aider à vous authentifier.`,
      speech: [

      ],
      ariaLabel: `Entrez votre email pour nous aider à vous authentifier.`
    },
    closePopin: {
      text: `Fermer la pop-in`,
      speech: [

      ],
      ariaLabel: `Fermer la pop-in`
    },
    resendMessaegAction: {
      text: `Renvoyez-moi un mot de passe`,
      speech: [

      ],
      ariaLabel: `Renvoyez-moi un mot de passe`
    }
  },
  'HeaderLinks': {
    enableTextToSpeech: {
      text: `Lecture vocale`,
      speech: [
        { speech: `La fonctionnalité de lecture du texte est maintenant activée.`, queue: true },
        { speech: `Vous pouvez double cliquer, ou faire un clic long sur les textes du site ou sur cette même icône pour avoir une version vocalisée du texte.`, queue: true },
        { speech: `Pour un rendu de voix optimale, utilisez si possible le navigateur Chrome.`, queue: true }
      ],
      ariaLabel: `Activer le text to Speech`
    },
    accessibilityRegister: {
      text: `Registre d’accessibilité`,
      speech: [
        { speech: `Registre d’accessibilité.`, queue: false },
      ],
      ariaLabel: `lien vers le registre accessibilité de l'université catholique de Lille`
    },
    referentMAH: {
      text: `Nos référents MAH`,
      speech: [
        { speech: `Nos référents MAH.`, queue: false },
      ],
      ariaLabel: `lien vers les référents MAH`
    }
  },
  'KDAE_CareFolderView': {
    closePopinButton: {
      text: `Fermer`,
      speech: [

      ],
      ariaLabel: 'Fermer cette pop-in sans envoyer le formulaire'
    },
    sendFormButton: {
      text: `Envoyez votre demande`,
      speech: [

      ],
      ariaLabel: "Bouton pour enregistrer et envoyer votre demande"
    },
    userOptinMAH: {
      text: ` En cochant cette case, j'accepte de recevoir par mail des informations de la part de la Mission Accueil Handicap de l’Université Catholique de Lille (invitations à des événements, offres de stages et d’emplois...)`,
      speech: [
        { speech: ` En cochant cette case, j'accepte de recevoir par mail des informations de la part de la Mission Accueil Handicap de l’Université Catholique de Lille (invitations à des événements, offres de stages et d’emplois...)`, queue: true }
      ]
    },
    userProductPackage1: {
      ariaLabel: `Accessibilité des locaux (ex: ascenseur, salle d'épreuve au rez-de-chaussée, salle isolée...)`,
      text: `<div><b>Accessibilité des locaux</b> (ex: ascenseur, salle d'épreuve au rez-de-chaussée, salle isolée...)</div>`,
      speech: [
        { speech: `Accessibilité des locaux (exemple: ascenseur, salle d'épreuve au rez-de-chaussée, salle isolée...)`, queue: true }
      ]
    },
    userProductPackage2: {
      ariaLabel: `Installation particulière de la salle (ex: éclairage particulier, proximité des toilettes...)`,
      text: `<div><b>Installation particulière de la salle</b> (ex: éclairage particulier, proximité des toilettes...)</div>`,
      speech: [
        { speech: `Installation particulière de la salle (Example: éclairage particulier, proximité des toilettes...)`, queue: true }
      ]
    },
    userProductPackage3: {
      ariaLabel: `Installation particulière de matériel (ex: loupe, ordinateur, logiciel...)`,
      text: `<div><b>Installation particulière de matériel</b> (ex: loupe, ordinateur, logiciel...)</div>`,
      speech: [
        { speech: `Installation particulière de matériel (exemples: loupe, ordinateur, logiciel...)`, queue: true }
      ]
    },
    userProductPackage4: {
      ariaLabel: `Assistance d'une tierce personne`,
      text: `<div><b>Assistance d'une tierce personne</b></div>`,
      speech: [
        { speech: `Assistance d'une tierce personne`, queue: true }
      ]
    },
    userProductPackage5: {
      ariaLabel: `Agrandissement des documents`,
      text: `<div><b>Agrandissement des documents</b></div>`,
      speech: [
        { speech: `Agrandissement des documents`, queue: true }
      ]
    },
    userProductPackage6: {
      ariaLabel: `Majoration d'un tiers temps supplémentaire pour les épreuves`,
      text: `<div><b>Majoration d'un tiers temps supplémentaire pour les épreuves</b></div>`,
      speech: [
        { speech: `Majoration d'un tiers temps supplémentaire pour les épreuves`, queue: true }
      ]
    },
    userProductPackage8: {
      ariaLabel: `Situation stage à l'étranger`,
      text: `<div><b>Situation stage à l'étranger</b></div>`,
      speech: [
        { speech: `Situation stage à l'étranger`, queue: true }
      ]
    },
    userProductPackage7: {
      ariaLabel: `Autres`,
      text: `<b>Autres</b>`,
      speech: [
        { speech: `Autres`, queue: true }
      ]
    },
    title: {
      text: `Formulaire de demande d'aménagements`,
      speech: [
        { speech: `Ce formulaire vous permet d'initier ou de renouveler vos demandes d'aménagements pour vos cours et vos examens.`, queue: false }
      ],
      ariaDescribedby: `formulaire-permettant-d-initier-ou-renouveler-votre-demande-d'amenagement-pour-vos-cours-ou-examens`
    },
    subscribeWithSocialNetwork: {
      text: `Inscrivez-vous avec votre compte`,
      speech: [
        { speech: `Inscrivez-vous avec votre compte`, queue: false }
      ],
      ariaLabelledby: `formulaire-d-inscription-et-de-demande-d-amenagements`
    },
    subscribeWithEmailAndPassword: {
      text: `créez votre compte avec votre email et mot de passe`,
      speech: [
        { speech: `créez votre compte avec votre email et mot de passe`, queue: false }
      ],
      ariaLabelledby: `formulaire-d-inscription-et-de-demande-d-amenagements`
    },
    subscribeWithSocialNetworkActif: {
      text: `La connexion au site se fera par le bouton ___externalProvider___.
      <br />L'université vous contactera avec l'adresse mail suivante  : <b>___userEmail___</b>
      `,
      speech: [
        {
          speech: `Votre authentification sera faite par ___externalProvider___
        L'université vous contactera avec l'adresse mail suivante  : ___userEmail___
        `, queue: false
        }
      ],
      ariaLabelledby: `formulaire-d-inscription-et-de-demande-d-amenagements`
    },
    subscribeWithSocialNetworkChooseAnotherMethod: {
      text: `Choisir une autre adresse`,
      speech: [
        {
          speech: `Choisir une autre adresse`, queue: false
        }
      ],
      ariaLabelledby: `formulaire-d-inscription-et-de-demande-d-amenagements`
    },
    explain: {
      text: `Ce formulaire vous permet d'initier ou de renouveler vos demandes d'aménagements pour vos cours et vos examens.`,
      speech: [
        { speech: `Ce formulaire vous permet d'initier ou de renouveler vos demandes d'aménagements pour vos cours et vos examens.`, queue: false }
      ],
      ariaLabelledby: `formulaire-d-inscription-et-de-demande-d-amenagements`
    },
    introItemsText: {
      text: `Choisissez les sujets que vous souhaitez aborder avec le médecin universitaire.`,
      speech: [
        { speech: `Choisissez les sujets que vous souhaitez aborder avec le médecin universitaire.`, queue: false }
      ],
      ariaLabelledby: `choose-subjects`
    },
    form: {
      userFirstName: {
        text: `Prénom`,
        speech: [

        ],
        ariaLabel: 'Prénom',
        textMessage: 'Le champ prénom est vide'
      },
      userLastName: {
        text: `Nom`,
        speech: [

        ],
        ariaLabel: 'Nom',
        textMessage: 'Le champ nom est vide'
      },
      userPassword: {
        text: `Mot de passe`,
        speech: [

        ],
        ariaLabel: 'mot de passe',
        textMessage: 'Le mot de passe doit contenir au moins 5 caractères avec au moins une minuscule + une majuscule + un chiffre + un caractère spécial. Par exemple %!@#$&*-.+~{[|^]}'
      },
      userBirthDay: {
        text: `Date de naissance`,
        textMessage: 'Le champ date de naissance est vide ou invalide',
        speech: [

        ],
        ariaLabel: 'Date de naissance'
      },
      establishmentId: {
        text: `Choisissez dans la liste déroulante`,
        textMessage: 'Le champ établissement est obligatoire',
        speech: [
        ],
        label: 'Etablissement',
        ariaLabel: 'Etablissement'
      },
      userGender: {
        text: `Sexe`,
        speech: [

        ],
        ariaLabel: 'Sexe'
      },
      userGenderMale: {
        text: `M.`,
        speech: [

        ],
        ariaLabel: ''
      },
      userGenderFemale: {
        text: `Mme`,
        speech: [

        ],
        ariaLabel: ''
      },
      userRenew: {
        text: `S'agit-il d'une demande de renouvellement ?`,
        speech: [

        ],
        ariaLabel: 'Demande de renouvellement'
      },
      userRenewYes: {
        text: `Oui`,
        speech: [

        ],
        ariaLabel: ''
      },
      userRenewNo: {
        text: `Non`,
        speech: [

        ],
        ariaLabel: ''
      },
      userEmail: {
        text: `Email`,
        speech: [

        ],
        ariaLabel: 'Courriel',
        placeHolder: 'Email personnel',
        textMessage: `L'email est obligatoire`
      },
      userEmailCatho: {
        text: `Votre adresse email d’établissement (si vous ne disposez pas encore d’une adresse institutionnelle délivrée par votre établissement, vous pouvez temporairement utiliser une adresse personnelle) `,
        speech: [

        ],
        ariaLabel: 'Email de la catho',
        placeHolder: 'email finissant par @univ-catholille.fr'
      },
      userMobilePhone: {
        text: `Numéro de mobile`,
        speech: [

        ],
        ariaLabel: 'Numéro de téléphone',
        textMessage: 'Le numéro de téléphone ne semble pas valide'
      },
      userSchoolYear: {
        text: `Année`,
        speech: [

        ],
        ariaLabel: 'Année dans la scolarité. Licence 1, Licence 2, Licence 3, Master 1, Master 2, Échange, Autre (préciser)'
      },
      userSchoolYearOther: {
        text: `Autre. Précisez :`,
        speech: [

        ],
        ariaLabel: 'Autre. Précisez :',
        textMessage: 'Ce champ est obligatoire'
      },
      userTypeHandicap: {
        text: `Temporalité `,
        speech: [
        ],
        ariaLabel: 'Temporalité : pour l\'ensemble du cursus, pour l\'année, ponctuelle'
      },
      userTrainingLabel: {
        text: `Intitulé de votre formation:`,
        speech: [

        ],
        ariaLabel: 'Intitulé de votre formation:',
        textMessage: 'Ce champ est obligatoire'
      },
      userProductPackage7Detail: {
        text: `Précisez :`,
        speech: [

        ],
        ariaLabel: 'Précisez :',
        textMessage: 'Ce champ est obligatoire'
      }
    }
  },
  'home': {
    login: {
      text: `Bonjour, identifiez-vous`,
      speech: [

      ],
      ariaDescribedby: ``,
      ariaLabelledby: ``
    },
    title: {
      text: `
  
  Formulaire de demande d'aménagements
  
  pour les étudiants
  
  en situation de handicap
  
  `,
      speech: [
        { speech: `Formulaire de demande d'aménagements pour les étudiants en situation de handicap`, queue: false }
      ]
    },
    mahLogo: {
      text: `Logo de la Mission Accueil Handicap de l'Université Catholique de Lille`,
      speech: [

      ]
    },
    intro: {
      text: `
  
  L'Université Catholique de Lille encourage l'accès de chacun aux études supérieures.
  
  Si vous êtes en situation de handicap, vous pouvez faire une demande d’aménagements au médecin universitaire (agréé par la MDPH). Celui-ci formulera des préconisations qui seront ensuite adressées à votre établissement qui étudiera la faisabilité de la demande et vous informera de la décision prise.`,
      speech: [
        { speech: `L'Université Catholique de Lille encourage l'accès de chacun aux études supérieures.`, queue: true },
        { speech: `Si vous êtes en situation de handicap, vous pouvez faire une demande d’aménagements au médecin universitaire(agréé par la MDPH).`, queue: true },
        { speech: `Celui-ci formulera des préconisations qui seront ensuite adressées à votre établissement qui étudiera la faisabilité de la demande et vous informera de la décision prise.`, queue: true }
      ]
    },
    step1TabButton: {
      text: `Etape 1`,
      speech: [

      ]
    },
    step1Title: {
      text: `Inscrivez- vous`,
      speech: [

      ]
    },
    step1Alt: {
      text: `Etape 1. Inscrivez - vous en cliquant sur le bouton « s'inscrire »`,
      speech: [

      ]
    },
    step1RenewTitle: {
      text: `Connectez-vous`,
      speech: [

      ]
    },
    step1RenewAlt: {
      text: `Etape 1. Connectez-vous en suivant le bouton « renouvellement »`,
      speech: [

      ]
    },
    step1RenewButtonLabel: {
      text: `Bouton qui ouvre la pop-in du formulaire pour se connecter dans le cas d'un renouvellement.`,
      speech: [

      ]
    },
    step1ButtonLabel: {
      text: `Bouton qui ouvre la pop-in du formulaire pour une première demande.`,
      speech: [

      ]
    },
    step1: {
      text: ` Remplissez le formulaire ci-dessous afin d'initier votre première demande d'aménagements`,
      speech: [
        { speech: `Etape une. Remplissez le formulaire ci-dessous afin d'initier votre demande d'aménagements ou connectez vous sur votre espace pour renouveler votre demande de l’année dernière`, queue: false }
      ]
    },
    step1Renew: {
      text: ` Connectez-vous et renouvelez vos demandes`,
      speech: [
        { speech: `Etape une. Connectez-vous et renouvelez vos demandes`, queue: false }
      ]
    },
    step1More: {
      text: `
  
  Cette demande sera à renouveler chaque année.
    
  Après avoir envoyé votre formulaire en ligne n'oubliez pas de prendre rendez-vous avec le médecin universitaire.
  
  `,
      speech: [
        { speech: `Cette demande sera à renouveler chaque année.`, queue: true },
        { speech: `Après avoir envoyé votre formulaire en ligne, n'oubliez pas de prendre rendez-vous avec le médecin universitaire (voir étape numéro 2).`, queue: true }
      ]
    },
    step2TabButton: {
      text: `Etape 2`,
      speech: [

      ]
    },
    step2: {
      text: `
  
  PRENEZ RDV AVEC 
  LE MEDECIN UNIVERSITAIRE 
  
  `,
      speech: [
        { speech: `Etape 2. Après avoir transmis votre demande à l'étape une. Prenez rendez-vous avec le médecin universtitaire auprès de l’accueil du CPSU : 03, 28, 04, 02, 40. `, queue: false }
      ]
    },
    step2Suite: {
      text: `
  
  03.28.04.02.40
  
  Centre Polyvalent de Santé Universitaire, 47 bis Rue du Port 59000 Lille
  
  `,
      speech: [

      ]
    },
    step2More: {
      text: `
  
  Le médecin universitaire est agréé par la Maison Départementale des Personnes Handicapées.
  
  Le médecin émet un avis sur les aménagements demandés et réalise des préconisations qui seront transmises à votre établissement.
  
  Lors du RDV, n'oubliez pas de venir avec les documents médicaux relatifs à votrepathologie.
  
  `,
      speech: [
        { speech: `Le médecin universitaire est agréé par la Maison Départementale des Personnes Handicapées.`, queue: true },
        { speech: `Le médecin émet un avis sur les aménagements demandés et réalise des préconisations qui seront transmises à votreétablissement.`, queue: true },
        { speech: `Lors du rendez-vous, n'oubliez pas de venir avec les documents médicaux relatifs à votre pathologie.`, queue: true }
      ]
    },
    step3TabButton: {
      text: `Etape 3`,
      speech: [

      ]
    },
    step3: {
      text: `
  
  L'établissement prend une décision au sujet des aménagements réalisables et vous en informe.
  
  `,
      speech: [
        { speech: `Etape 3. L'établissement prend une décision au sujet des aménagements, lors des cours et examens, et vous informe`, queue: true },
        { speech: ` Les propositions des établissements vous seront envoyées par courrier électronique. Pensez à bien vérifier votre adresse email lors de l'inscription (cf étape 1).`, queue: true }
      ]
    }
    ,
    step3More: {
      text: `
  
  Les propositions des établissements vous seront envoyées par courrier électronique.
  
  Pensez à bien vérifier votre adresse email lors de l'inscription.
  
  `,
      speech: [

      ]
    },
    step3Title: {
      text: `Décision de l'établissement`,
      speech: [

      ]
    },
    step3Alt: {
      text: `Step 3. Décision de l'établissement`,
      speech: [

      ]
    },
    signUpButton: {
      text: `Première demande`,
      speech: [

      ]
    },
    renewButton: {
      text: `Renouvellement`,
      speech: [

      ]
    },
  },
  'legalNotice': {
    title: {
      text: `Mentions légales.`,
      speech: [
      ],
      ariaLabelledby: `Mentions légales.`
    },
    dpo1: {
      text: `
<br />Les informations recueillies sur ce formulaire sont enregistrées dans une base informatisée par l'Institut Catholique de Lille à des fins d’accompagnement pédagogique et de mise en place des aménagements nécessaires.
<br />
<br />Elles sont conservées durant l’année universitaire en cours.
<br />
<br />La base légale du traitement est le consentement, il est conforme au règlement européen (UE) 2016/679 du 27 avril 2016 relatif à la protection des personnes physiques à l’égard du traitement des données à caractère personnel, et à la loi « Informatique, Fichiers et Libertés » n°78-17 du 6 janvier 1978 modifiée.
<br />
<br />Pour exercer votre droit d'accès aux données vous concernant à tout moment et les faire rectifier ou supprimer en contactant : mah@univ-catholille.fr
<br />
<br />Pour toute question sur le traitement de vos données dans ce dispositif, vous pouvez contacter notre délégué à la protection des données (DPO) : dpo@univ-catholille.fr
<br />
<br />Si vous estimez, après nous avoir contactés, que vos droits Informatique et Libertés ne sont pas respectés, vous pouvez adresser une réclamation en ligne à la CNIL : www.cnil.fr ou par courrier postal ; la CNIL, 3 Place de Fontenoy – TSA 80715 – 75334 PARIS CEDEX 07.
                                          
`,
      speech: [

      ],
      ariaDescribedby: ``,
      ariaLabelledby: ``
    },
    dpo2: {
      text: `

<br />Les informations fournies lors de la création de votre compte sont enregistrées dans une base informatisée par l'Institut Catholique de Lille à des fins de gestion informatique.
<br />
<br />Elles sont conservées tant que votre compte demeure actif.
<br />
<br />La base légale du traitement est le consentement, il est conforme au règlement européen (UE) 2016/679 du 27 avril 2016 relatif à la protection des personnes physiques à l’égard du traitement des données à caractère personnel, et à la loi « Informatique, Fichiers et Libertés » n°78-17 du 6 janvier 1978 modifiée.
<br />
<br />Vous pouvez accéder à votre profil et modifier ou supprimer vos données en allant sur le site mah.univ-catholille.fr puis en cliquant sur l’onglet « votre compte » et le bouton « désactiver mon compte »
<br />
<br />Pour exercer votre droit d'accès aux données vous concernant à tout moment et les faire rectifier ou supprimer en contactant : mah@univ-catholille.fr
<br />
<br />Pour toute question sur le traitement de vos données dans ce dispositif, vous pouvez contacter notre délégué à la protection des données (DPO) : dpo@univ-catholille.fr
<br />
<br />Si vous estimez, après nous avoir contactés, que vos droits Informatique et Libertés ne sont pas respectés, vous pouvez adresser une réclamation en ligne à la CNIL : www.cnil.fr ou par courrier postal ; la CNIL, 3 Place de Fontenoy – TSA 80715 – 75334 PARIS CEDEX 07.
`,
      speech: [

      ],
      ariaDescribedby: ``,
      ariaLabelledby: ``
    },
  }
}
