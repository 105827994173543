import { ContactMailOutlined, SchoolRounded, LockRounded, Mail, VisibilityRounded, SendRounded, CloseRounded } from '@material-ui/icons'
import { InputAdornment, Divider } from '@material-ui/core'
import React, { Fragment } from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import { Tooltip, withWidth, Grid, FormGroup, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, IconButton, Slide } from '@material-ui/core'
import Speech from "speak-tts";
import superagent from 'superagent'
import config from 'config'
import classNames from 'classnames';
import green from '@material-ui/core/colors/green';
import CircularProgress from '@material-ui/core/CircularProgress';
import loginPageStyle from 'assets/jss/material-kit-react/views/loginPage.jsx'
import GridContainer from 'components/KMLogin/Grid/GridContainer.jsx'
import GridItem from 'components/KMLogin/Grid/GridItem.jsx'
import Card from 'components/KMLogin/Card/Card.jsx'
import Header from 'components/KMLogin/Header/Header.jsx'
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import CardHeader from 'components/KMLogin/Card/CardHeader.jsx'
import KDAETextField from 'components/Material/KDAETextField'
import KDAEDatePicker from 'components/Material/KDAEDatePicker'
import KDAEPhone from 'components/Material/KDAEPhone'
import KDAERadio from 'components/Material/KDAERadio'
import KDAECheckbox from 'components/Material/KDAECheckbox'
import KDAESelect from 'components/Material/KDAESelect'
import { withLangStore } from "stores/KLangProvider.js";
import image from 'assets/img/shutterstock_431020450.jpg'
import {
    GoogleReCaptcha
} from 'react-google-recaptcha-v3';
import GoogleButton from 'components/SocialNetworkButton/GoogleButton'
import MicrosoftButton from 'components/SocialNetworkButton/MicrosoftButton'



import moment from 'moment'
import 'moment/min/locales'
import LegalNotice from './LegalNotice';
import KMEstablishment from './KMEstablishment';
import DividerWithText from 'components/Divider/DividerWithText'
moment.locale('fr')

const styles = theme => ({

    button: {
        margin: theme.spacing(1),
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    dialogContent: {
        margin: theme.spacing(2)
    },
    dialogActions: {
        margin: theme.spacing(2)
    },
    root: {
        flexGrow: 1,
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    wrapper: {
        margin: theme.spacing(),
        position: 'relative',
    },
});


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class KDAE_CareFolderView extends React.Component {
    constructor(props) {
        super(props)
        this._isMounted = false

        this.state = {
            flagPasswordVisible: false,
            yourNotABot: false,
            cardAnimaton: 'cardHidden',
            success: false,
            loadingSave: false,
            userFirstName: '',
            userFirstNameTextMessage: '',
            userFirstNameError: '',
            userLastName: '',
            userLastNameTextMessage: '',
            userLastNameError: '',
            userBirthDay: '',
            userEmail: '',
            userEmailTextMessage: ``,
            userEmailError: '',
            userMobilePhone: '',
            userMobilePhoneTextMessage: ``,
            userMobilePhoneError: '',
            establishmentId: 0,
            establishmentIdError: false,
            userSchoolYear: 'L1',
            userTypeHandicap: 'duringYear',
            userSchoolYearOther: '',
            userSchoolYearOtherTextMessage: ``,
            userSchoolYearOtherError: false,
            userSchoolYearOtherRequired: false,
            userTrainingLabel: '',
            userTrainingLabelTextMessage: ``,
            userTrainingLabelError: false,
            userTrainingLabelRequired: true,
            userOptinMAH: false,
            userGender: 'M',
            userRenew: false,
            userProductPackage1: false,
            userProductPackage2: false,
            userProductPackage3: false,
            userProductPackage4: false,
            userProductPackage5: false,
            userProductPackage6: false,
            userProductPackage8: false,
            userProductPackage7: false,
            userProductPackage7Detail: '',
            userProductPackage7DetailTextMessage: ``,
            userProductPackage7DetailRequired: false,
            userProductPackage7DetailError: false,
            userEmailLabel: this.props.langStore.KDAE_CareFolderView.form.userEmail.text,
            userEmailAriaLabel: this.props.langStore.KDAE_CareFolderView.form.userEmail.ariaLabel,
            userEmailPlaceHolder: this.props.langStore.KDAE_CareFolderView.form.userEmail.placeHolder,
            userMicrosoftExternalId: null,
            userGoogleExternalId: null,

            socialNetworkSubscription: false,
            externalProvider: '',
            userProfileImageUrl: null
        }
    }
    handleRemoveSocialNetworkSubscription = () => {
        this.setState({
            userMicrosoftExternalId: null,
            userGoogleExternalId: null,

            externalProvider: '',
            socialNetworkSubscription: false,
            userEmail: ''
        })
    }
    handleSubscribeWithProvider = async (providerResponse) => {
        if (!providerResponse) {
            let message = `${providerResponse.externalProvider} envoi un identifiant qui n'est pas reconnu dans notre base. Nous ne retrouvons pas votre fiche. Vous pouvez réessayer en vous reconnectant de ${providerResponse.externalProvider}. Si le problème persiste utilisez un autre réseau social accessible ou entrez votre email et votre mot de passe dans le formulaire`
            let autoHideDuration = 25000

            let variant = 'warning'
            this.props.enqueueSnackbar(message, { variant, autoHideDuration })
            return
        }

        if (providerResponse.email === '' || !providerResponse.email.match(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,6}$/i)) {
            let message = `Votre compte ${providerResponse.externalProvider} a bien été détécté, néanmoins il semble qu'il n'y ait pas d'email rattaché à ce compte. Pour communiquer avec vous, l'université aura besoin d'un email valide. Vous pouvez soit complèter votre email chez ${providerResponse.externalProvider}. Soit utiliser un autre bouton réseau social. Soit saisir vous même le champ email et mot de passe sur le formulaire de demande.`
            let autoHideDuration = 25000
            let variant = 'warning'
            this.props.enqueueSnackbar(message, { variant, autoHideDuration })
            return
        }
        if (providerResponse.externalProvider === 'microsoft')
            this.setState({
                userMicrosoftExternalId: providerResponse.externalId,
            })
        else if (providerResponse.externalProvider === 'google') {
            this.setState({
                userGoogleExternalId: providerResponse.externalId,
            })
        }
        this.setState({
            externalProvider: providerResponse.externalProvider,
            socialNetworkSubscription: true,
            userEmail: providerResponse.email,
            userProfileImageUrl: providerResponse.profile.imageUrl,
        })
        if (this.state.userLastName === '' && providerResponse.profile.lastName !== '') {
            this.setState({
                userLastName: providerResponse.profile.lastName
            })
        }
        if (this.state.userFirstName === '' && providerResponse.profile.firstName !== '') {
            this.setState({
                userFirstName: providerResponse.profile.firstName
            })
        }
        let message = `L'identification a réussi. Verifiez bien l'email que ${providerResponse.externalProvider} nous a transmis : ${providerResponse.email}.`
        let variant = 'success'
        this.props.enqueueSnackbar(message, { variant })
    }
    componentDidMount() {
        // const token = await this.props.googleReCaptchaProps.executeRecaptcha('homepage');
        this._isMounted = true

        setTimeout(
            function () {
                if (this._isMounted) {
                    this.setState({ cardAnimaton: '' })
                }
            }.bind(this),
            450
        )
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.etablissementIdError !== this.state.etablissementIdError) {
            console.log('etablissementIdError state has changed.')
        }
    }
    componentWillUnmount() {
        this._isMounted = false
    }
    handleSpeech = (sentenceNumber) => {
        if (sessionStorage.textToSpeechEnable === 'true' || !("textToSpeechEnable" in sessionStorage)) { } else { return }
        let textToSpeech = []
        const { langStore } = this.props
        switch (sentenceNumber) {
            case 1:
                textToSpeech = langStore.KDAE_CareFolderView.title.speech
                break;
            case 2:
                textToSpeech = langStore.KDAE_CareFolderView.introItemsText.speech
                break;
            default:
                textToSpeech = []
        }
        if (textToSpeech.length < 1) return
        const speech = new Speech();
        // speech.setVoice('Fiona')
        let lang = sessionStorage.prefsLang || 'fr_FR'
        speech.setLanguage(lang)
        speech.init({
            'lang': lang
        }).then(data => {
            console.log("Speech is ready", data);
        })
        textToSpeech.map(textToSpeech =>
            speech
                .speak({
                    text: textToSpeech.speech,
                    queue: textToSpeech.queue,
                    listeners: {
                        onstart: () => {
                            console.log("Start utterance");
                        },
                        onend: () => {
                            console.log("End utterance");
                        },
                        onresume: () => {
                            console.log("Resume utterance");
                        },
                        onboundary: event => {
                            console.log(
                                event.name +
                                " boundary reached after " +
                                event.elapsedTime +
                                " milliseconds."
                            );
                        }
                    }
                })
                .then(data => {
                    console.log("Success !", data);
                })
                .catch(e => {
                    console.error("An error occurred :", e);
                })
        )
    }
    onChangeKDAEPhone = (value) => { this.setState({ userMobilePhone: value }); }
    handleFlagPasswordVisible = () => this.setState({ flagPasswordVisible: !this.state.flagPasswordVisible })
    render() {
        let myWidth = this.props.width === 'sm' || this.props.width === 'xs' ? 'smartphone' : 'large'


        const { classes, langStore, ...rest } = this.props;
        const { loadingSave, success } = this.state
        const buttonClassname = classNames({
            [classes.buttonSuccess]: success,
        });
        const fullScreen = this.props.width === 'sm' || this.props.width === 'xs' ? true : false
        let closeImg = { cursor: 'pointer', zIndex: 10000 };

        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.handleClose}
                // scroll={myWidth==='smartphone'?"paper":'scroll'}
                TransitionComponent={Transition}
                fullWidth={true}
                fullScreen={fullScreen}
                maxWidth="lg"
                aria-describedby={langStore.KDAE_CareFolderView.title.ariaDescribedby}
                aria-labelledby={langStore.KDAE_CareFolderView.explain.ariaLabelledby}

            >

                <div className="LoginPage">

                    <Header
                        absolute
                        color="transparent"
                        brandImage={require('assets/img/header-logo-catho.png')}
                        brand=""
                        rightLinks={<HeaderLinks

                            isModal={true}
                        />}
                        {...rest}
                    />
                    {myWidth === 'smartphone' &&
                        <IconButton
                            aria-label={langStore.KDAE_CareFolderView.closePopinButton.ariaLabel}
                            onClick={this.props.handleClose} style={closeImg}>
                            <CloseRounded />
                        </IconButton>
                    }
                    <div
                        className={classes.pageHeader}
                        style={{
                            backgroundImage: 'url(' + image + ')',
                            backgroundSize: 'cover',
                            backgroundPosition: 'top center'
                        }}
                    >
                        <div className={classes.container}>
                            <GridContainer justify="center">
                                <GridItem xs={12} sm={12} md={8}>
                                    <Card className={classes[this.state.cardAnimaton]}>

                                        <form onSubmit={this.handleSubmitForm} className="KDAE_CareFoldereView" ref={x => (this.inputWrapper = x)}>
                                            <DialogTitle id={langStore.KDAE_CareFolderView.explain.ariaDescribedby}>
                                                <CardHeader color="primary" className={classes.cardHeader}>
                                                    {langStore.KDAE_CareFolderView.title.text}
                                                </CardHeader>
                                            </DialogTitle>

                                            <p className={classes.divider}><LegalNotice typeTexteDPO={1} /></p>
                                            <DialogContent className={classes.dialogContent}>
                                                <DialogContentText id={langStore.KDAE_CareFolderView.title.ariaLabelledby}>
                                                    <span onClick={e => this.handleSpeech(1)}>
                                                        {langStore.KDAE_CareFolderView.explain.text}
                                                        <br />
                                                        <br />

                                                    </span>
                                                    <Grid container spacing={24}>
                                                        {!!this.state.socialNetworkSubscription === false &&
                                                            <>
                                                                <Grid item sm={12} style={{ textAlign: 'center' }}>
                                                                    <br />
                                                                    {langStore.KDAE_CareFolderView.subscribeWithSocialNetwork.text}
                                                                </Grid>
                                                                <Grid item sm={1}></Grid>

                                                                <Grid item sm={4}>
                                                                    <Tooltip
                                                                        title="Inscrivez-vous via votre compte Google"
                                                                    >
                                                                        <GoogleButton
                                                                            clientId={config.socialNetwork.google.clientId}
                                                                            fullWidth
                                                                            text="Google"
                                                                            onClick={this.handleSubscribeWithProvider}
                                                                        />
                                                                    </Tooltip>
                                                                </Grid>
                                                                <Grid item sm={2}></Grid>
                                                                <Grid item sm={4}>
                                                                    <Tooltip
                                                                        title="Inscrivez-vous via votre compte Microsoft"
                                                                    >
                                                                        <MicrosoftButton
                                                                            clientId={config.socialNetwork.microsoft.clientId}
                                                                            fullWidth
                                                                            text="Microsoft"
                                                                            onClick={this.handleSubscribeWithProvider}
                                                                        />
                                                                    </Tooltip>
                                                                </Grid>
                                                                <Grid item sm={1}></Grid>
                                                            </>

                                                        }
                                                        {!!this.state.socialNetworkSubscription === false &&
                                                            <Grid item sm={12} style={{ textAlign: 'center' }}>
                                                                <br />
                                                                <DividerWithText>{langStore.conjunctionOr}</DividerWithText>

                                                                <br />
                                                                {langStore.KDAE_CareFolderView.subscribeWithEmailAndPassword.text}
                                                            </Grid>

                                                        }
                                                        <Grid item sm={2}></Grid>

                                                        {!!this.state.socialNetworkSubscription === true &&
                                                            <>
                                                                <Grid item xs={12}>
                                                                    <span dangerouslySetInnerHTML={{ __html: langStore.KDAE_CareFolderView.subscribeWithSocialNetworkActif.text.replace(/___externalProvider___/gi, this.state.externalProvider).replace(/___userEmail___/gi, this.state.userEmail) }}>

                                                                    </span>

                                                                </Grid>
                                                                <Grid item xs={7}></Grid>
                                                                <Grid item xs={5}>
                                                                    <Button onClick={this.handleRemoveSocialNetworkSubscription}><ContactMailOutlined></ContactMailOutlined>&nbsp;{langStore.KDAE_CareFolderView.subscribeWithSocialNetworkChooseAnotherMethod.text}</Button>
                                                                </Grid>
                                                            </>
                                                        }

                                                        {!!this.state.socialNetworkSubscription === false &&
                                                            <Grid item sm={4}>
                                                                <KDAETextField
                                                                    autoFocus={myWidth === 'smartphone' ? false : !!this.state.socialNetworkSubscription === false ? true : false}
                                                                    fullWidth={true}
                                                                    id="userEmail"
                                                                    name="userEmail"
                                                                    required
                                                                    label={this.state.userEmailLabel}
                                                                    initialValue={this.state.userEmail}
                                                                    margin="normal"
                                                                    placeholder={this.state.userEmailPlaceHolder}
                                                                    aria-label={this.state.userEmailAriaLabel}
                                                                    helperText={this.state.userEmailTextMessage !== '' ? this.state.userEmailTextMessage : ' '}
                                                                    error={this.state.userEmailError}
                                                                    handleInputChange={this.handleInputChange('userEmail')}
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                <Mail className={classes.inputIconsColor} />

                                                                            </InputAdornment>
                                                                        )
                                                                    }}
                                                                />
                                                            </Grid>
                                                        }
                                                        {!!this.state.socialNetworkSubscription === false &&
                                                            <Grid item sm={1}></Grid>
                                                        }
                                                        {!!this.state.socialNetworkSubscription === false &&
                                                            <Grid item sm={4}>
                                                                <KDAETextField
                                                                    id="userPassword"
                                                                    name="userPassword"
                                                                    required
                                                                    fullWidth={true}
                                                                    label={langStore.KDAE_CareFolderView.form.userPassword.text}
                                                                    initialValue={this.state.userPassword}
                                                                    margin="normal"
                                                                    aria-label={langStore.KDAE_CareFolderView.form.userPassword.ariaLabel}
                                                                    helperText={this.state.userPasswordTextMessage !== '' ? this.state.userPasswordTextMessage : ' '}
                                                                    error={this.state.userPasswordError}
                                                                    handleInputChange={this.handleInputChange('userPassword')}
                                                                    InputProps={{
                                                                        type: !!this.state.flagPasswordVisible === true ? 'text' : 'password',
                                                                        endAdornment: (
                                                                            <InputAdornment position="end" onClick={this.handleFlagPasswordVisible} >
                                                                                {!!this.state.flagPasswordVisible === true ? <LockRounded /> : <VisibilityRounded />}

                                                                            </InputAdornment>
                                                                        )
                                                                    }}
                                                                />
                                                            </Grid>
                                                        }

                                                        <Grid item sm={12}><Divider /></Grid>

                                                        <Grid item sm={2}>
                                                            <KDAESelect
                                                                id="userGender"
                                                                name="userGender"
                                                                label={langStore.KDAE_CareFolderView.form.userGender.text}
                                                                initialValue={this.state.userGender}
                                                                datas={[
                                                                    { value: 'Mme', children: langStore.KDAE_CareFolderView.form.userGenderFemale.text },
                                                                    { value: 'M', children: langStore.KDAE_CareFolderView.form.userGenderMale.text }
                                                                ]}
                                                                aria-label={langStore.KDAE_CareFolderView.form.userGender.ariaLabel}
                                                                handleInputChange={this.handleInputChange('userGender')}
                                                            />
                                                        </Grid>
                                                        <Grid item sm={5}>
                                                            <KDAETextField
                                                                autoFocus={myWidth === 'smartphone' ? false : !!this.state.socialNetworkSubscription === true ? true : false}
                                                                id="userFirstName"
                                                                name="userFirstName"
                                                                required
                                                                label={langStore.KDAE_CareFolderView.form.userFirstName.text}
                                                                value={this.state.userFirstName}
                                                                margin="normal"
                                                                aria-label={langStore.KDAE_CareFolderView.form.userFirstName.ariaLabel}
                                                                helperText={this.state.userFirstNameTextMessage !== '' ? this.state.userFirstNameTextMessage : ' '}
                                                                error={this.state.userFirstNameError}
                                                                handleInputChange={this.handleInputChange('userFirstName')}
                                                            />
                                                        </Grid>
                                                        <Grid item sm={5}>
                                                            <KDAETextField
                                                                id="userLastName"
                                                                name="userLastName"
                                                                required
                                                                fullWidth={true}
                                                                label={langStore.KDAE_CareFolderView.form.userLastName.text}
                                                                value={this.state.userLastName}
                                                                margin="normal"
                                                                aria-label={langStore.KDAE_CareFolderView.form.userLastName.ariaLabel}
                                                                helperText={this.state.userLastNameTextMessage !== '' ? this.state.userLastNameTextMessage : ' '}
                                                                error={this.state.userLastNameError}
                                                                handleInputChange={this.handleInputChange('userLastName')}
                                                            />
                                                        </Grid>

                                                        <Grid item sm={2}></Grid>
                                                        <Grid item sm={5}>
                                                            <KDAEDatePicker
                                                                id="userBirthDay"
                                                                name="userBirthDay"
                                                                required
                                                                label={langStore.KDAE_CareFolderView.form.userBirthDay.text}
                                                                initialValue={this.state.userBirthDay}
                                                                margin="normal"
                                                                ampm={false}
                                                                disableFuture={true}
                                                                openTo="year"
                                                                format={"DD/MM/YYYY"}
                                                                views={["year", "month", "day"]}
                                                                helperText={this.state.userBirthDayTextMessage !== '' ? this.state.userBirthDayTextMessage : ' '}
                                                                error={this.state.userBirthDayError}
                                                                aria-label={langStore.KDAE_CareFolderView.form.userBirthDay.ariaLabel}
                                                                handleInputChange={this.handleInputChange('userBirthDay')}

                                                            />
                                                        </Grid>
                                                        <Grid item sm={5}>
                                                            <KDAEPhone
                                                                value={this.state.userMobilePhone}
                                                                defaultCountry={"fr"}
                                                                onlyCountries={["fr"]}
                                                                required
                                                                name="userMobilePhone"
                                                                label={langStore.KDAE_CareFolderView.form.userMobilePhone.text}
                                                                aria-label={langStore.KDAE_CareFolderView.form.userMobilePhone.ariaLabel}
                                                                helperText={this.state.userMobilePhoneTextMessage !== '' ? this.state.userMobilePhoneTextMessage : ' '}
                                                                error={this.state.userMobilePhoneError}
                                                                handleInputChange={this.handleInputChange('userMobilePhone')}
                                                            />
                                                        </Grid>
                                                        {/* <Grid item sm={12}>

                                                            <KDAESwitchFormGroup
                                                                formLabel={langStore.KDAE_CareFolderView.form.userRenew.text}>
                                                                <KDAESwitch
                                                                    datas={[
                                                                        { value: '1', label: `` },
                                                                    ]}
                                                                    name="userRenew"
                                                                    label={!!this.state.userRenew ? langStore.KDAE_CareFolderView.form.userRenewYes.text : langStore.KDAE_CareFolderView.form.userRenewNo.text}
                                                                    initialChecked={this.state.userRenew}
                                                                    customHandler={this.handleChangeUserRenew}
                                                                    aria-label={langStore.KDAE_CareFolderView.form.userRenew.ariaLabel}
                                                                />
                                                            </KDAESwitchFormGroup>
                                                        </Grid> */}
                                                        <Grid item sm={2}></Grid>
                                                        <Grid item sm={10}>
                                                            <KDAETextField
                                                                fullWidth={true}
                                                                id="userTrainingLabel"
                                                                label={langStore.KDAE_CareFolderView.form.userTrainingLabel.text}
                                                                aria-label={langStore.KDAE_CareFolderView.form.userTrainingLabel.ariaLabel}
                                                                helperText={this.state.userTrainingLabelTextMessage !== '' ? this.state.userTrainingLabelTextMessage : ' '}
                                                                initialValue={this.state.userTrainingLabel}
                                                                margin="normal"
                                                                name="userTrainingLabel"
                                                                required={this.state.userTrainingLabelRequired}
                                                                error={this.state.userTrainingLabelError}
                                                                handleInputChange={this.handleInputChange('userTrainingLabel')}
                                                            />
                                                        </Grid>
                                                        <Grid item sm={2}></Grid>

                                                        <Grid item sm={10}>
                                                            <KMEstablishment
                                                                fullWidth={true}
                                                                required={true}
                                                                error={this.state.establishmentIdError}
                                                                behaviors={
                                                                    {
                                                                        label: langStore.KDAE_CareFolderView.form.establishmentId.label,
                                                                        hintText: "",
                                                                        pattern: /.{1,}/,
                                                                        errorTextMessage: langStore.KDAE_CareFolderView.form.establishmentId.textMessage,
                                                                        placeholder: langStore.KDAE_CareFolderView.form.establishmentId.text,
                                                                        name: 'establishmentId',
                                                                        focusKey: '',
                                                                        className: 'KMEstablishmentId',
                                                                        minutesStep: null,
                                                                        format: null,
                                                                        formatMobile: null,
                                                                        id: 'KMEstablishmentId',
                                                                    }

                                                                }

                                                                initialValue={this.state.establishmentId}
                                                                // handleStepHasError={this.handleSetFormHasError}
                                                                handleInputChange={this.handleInputChange('establishmentId')}
                                                                inputProps={{
                                                                    type: 'text',
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <SchoolRounded className={classes.inputIconsColor} />
                                                                        </InputAdornment>
                                                                    )
                                                                }}
                                                            ></KMEstablishment>
                                                        </Grid>

                                                        <Grid item sm={1}></Grid>
                                                        <Grid item sm={11}>
                                                            <KDAERadio
                                                                datas={[
                                                                    { value: 'L1', label: 'L1' },
                                                                    { value: 'L2', label: 'L2' },
                                                                    { value: 'L3', label: 'L3' },
                                                                    { value: 'M1', label: 'M1' },
                                                                    { value: 'M2', label: 'M2' },
                                                                    { value: 'Echange', label: 'Echange' },
                                                                    {
                                                                        value: 'Other',
                                                                        label:
                                                                            <Grid container fullWidth>

                                                                                <Grid item sm="auto">
                                                                                    <KDAETextField
                                                                                        fullWidth={true}
                                                                                        id="other"
                                                                                        label={langStore.KDAE_CareFolderView.form.userSchoolYearOther.text}
                                                                                        aria-label={langStore.KDAE_CareFolderView.form.userSchoolYearOther.ariaLabel}
                                                                                        helperText={this.state.userSchoolYearOtherTextMessage !== '' ? this.state.userSchoolYearOtherTextMessage : ' '}
                                                                                        initialValue={this.state.userSchoolYearOther}
                                                                                        margin="normal"
                                                                                        name="userSchoolYearOther"
                                                                                        required={this.state.userSchoolYearOtherRequired}
                                                                                        error={this.state.userSchoolYearOtherError}
                                                                                        handleInputChange={this.handleInputChange('userSchoolYearOther')}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                    }
                                                                ]}
                                                                name="userSchoolYear"
                                                                aria-label={langStore.KDAE_CareFolderView.form.userSchoolYear.ariaLabel}
                                                                label={langStore.KDAE_CareFolderView.form.userSchoolYear.text}
                                                                initialValue={this.state.userSchoolYear}
                                                                row={true}
                                                                handleInputChange={this.handleInputChange('userSchoolYear')}
                                                            />
                                                        </Grid>
                                                        <Grid item sm={11}>
                                                            <KDAERadio
                                                                datas={[
                                                                    { value: "duringYear", label: "POUR L'ANNÉE" },
                                                                    { value: "punctual", label: "PONCTUELLE" },
                                                                    { value: 'continueTraining', label: "POUR L'ENSEMBLE DU CURSUS" },
                                                                ]}
                                                                name="userTypeHandicap"
                                                                aria-label={langStore.KDAE_CareFolderView.form.userTypeHandicap.ariaLabel}
                                                                label={langStore.KDAE_CareFolderView.form.userTypeHandicap.text}
                                                                initialValue={this.state.userTypeHandicap}
                                                                row={true}
                                                                handleInputChange={this.handleInputChange('userTypeHandicap')}
                                                            />
                                                        </Grid>
                                                        <Grid item sm={12}>
                                                            <span onClick={e => this.handleSpeech(2)}>
                                                                {langStore.KDAE_CareFolderView.introItemsText.text}

                                                            </span>
                                                        </Grid>
                                                        <Grid item sm={12}>
                                                            <FormGroup row={false}>
                                                                <KDAECheckbox
                                                                    name="userProductPackage1"
                                                                    label={<div dangerouslySetInnerHTML={{ __html: langStore.KDAE_CareFolderView.userProductPackage1.text }} />}
                                                                    aria-label={langStore.KDAE_CareFolderView.userProductPackage1.ariaLabel}
                                                                    initialChecked={this.state.userProductPackage1}
                                                                    handleInputChange={this.handleInputChange('userProductPackage1')}

                                                                />
                                                                <KDAECheckbox
                                                                    name="userProductPackage2"
                                                                    label={<div dangerouslySetInnerHTML={{ __html: langStore.KDAE_CareFolderView.userProductPackage2.text }} />}
                                                                    aria-label={langStore.KDAE_CareFolderView.userProductPackage2.ariaLabel}
                                                                    initialChecked={this.state.userProductPackage2}
                                                                    handleInputChange={this.handleInputChange('userProductPackage2')}

                                                                />
                                                                <KDAECheckbox
                                                                    name="userProductPackage3"
                                                                    label={<div dangerouslySetInnerHTML={{ __html: langStore.KDAE_CareFolderView.userProductPackage3.text }} />}
                                                                    aria-label={langStore.KDAE_CareFolderView.userProductPackage3.ariaLabel}
                                                                    initialChecked={this.state.userProductPackage3}
                                                                    handleInputChange={this.handleInputChange('userProductPackage3')}

                                                                />
                                                                <KDAECheckbox
                                                                    name="userProductPackage4"
                                                                    label={<div dangerouslySetInnerHTML={{ __html: langStore.KDAE_CareFolderView.userProductPackage4.text }} />}
                                                                    aria-label={langStore.KDAE_CareFolderView.userProductPackage4.ariaLabel}
                                                                    initialChecked={this.state.userProductPackage4}
                                                                    handleInputChange={this.handleInputChange('userProductPackage4')}

                                                                />
                                                                <KDAECheckbox
                                                                    name="userProductPackage5"
                                                                    label={<div dangerouslySetInnerHTML={{ __html: langStore.KDAE_CareFolderView.userProductPackage5.text }} />}
                                                                    aria-label={langStore.KDAE_CareFolderView.userProductPackage5.ariaLabel}
                                                                    initialChecked={this.state.userProductPackage5}
                                                                    handleInputChange={this.handleInputChange('userProductPackage5')}

                                                                />
                                                                <KDAECheckbox
                                                                    name="userProductPackage6"
                                                                    label={<div dangerouslySetInnerHTML={{ __html: langStore.KDAE_CareFolderView.userProductPackage6.text }} />}
                                                                    aria-label={langStore.KDAE_CareFolderView.userProductPackage6.ariaLabel}
                                                                    initialChecked={this.state.userProductPackage6}
                                                                    handleInputChange={this.handleInputChange('userProductPackage6')}

                                                                />
                                                                <KDAECheckbox
                                                                    name="userProductPackage8"
                                                                    label={<div dangerouslySetInnerHTML={{ __html: langStore.KDAE_CareFolderView.userProductPackage8.text }} />}
                                                                    aria-label={langStore.KDAE_CareFolderView.userProductPackage8.ariaLabel}
                                                                    initialChecked={this.state.userProductPackage8}
                                                                    handleInputChange={this.handleInputChange('userProductPackage8')}

                                                                />
                                                            </FormGroup>
                                                            <Grid container>
                                                                <Grid item sm="auto">
                                                                    <KDAECheckbox
                                                                        name="userProductPackage7"
                                                                        label={<div dangerouslySetInnerHTML={{ __html: langStore.KDAE_CareFolderView.userProductPackage7.text }} />}
                                                                        aria-label={langStore.KDAE_CareFolderView.userProductPackage7.ariaLabel}
                                                                        initialChecked={this.state.userProductPackage7}
                                                                        handleInputChange={this.handleInputChange('userProductPackage7')}

                                                                    />
                                                                </Grid>
                                                                <Grid item sm="9">
                                                                    <KDAETextField
                                                                        id="userProductPackage7Detail"
                                                                        name="userProductPackage7Detail"
                                                                        label={langStore.KDAE_CareFolderView.form.userProductPackage7Detail.text}
                                                                        aria-label={langStore.KDAE_CareFolderView.form.userProductPackage7Detail.ariaLabel}
                                                                        helperText={this.state.userProductPackage7DetailTextMessage !== '' ? this.state.userProductPackage7DetailTextMessage : ' '}
                                                                        fullWidth={true}
                                                                        multiline={true}
                                                                        initialValue={this.state.userProductPackage7Detail}
                                                                        required={this.state.userProductPackage7DetailRequired}
                                                                        error={this.state.userProductPackage7DetailError}
                                                                        handleInputChange={this.handleInputChange('userProductPackage7Detail')}
                                                                    />
                                                                </Grid>
                                                                <Grid item sm={12}>
                                                                    <KDAECheckbox
                                                                        datas={[
                                                                            { value: '1', label: `` },
                                                                        ]}
                                                                        aria-label="userOptinMAH"
                                                                        label={langStore.KDAE_CareFolderView.userOptinMAH.text}
                                                                        initialChecked={this.state.userOptinMAH}
                                                                        handleInputChange={this.handleInputChange('userOptinMAH')}

                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions className={classes.dialogActions}>
                                                <GoogleReCaptcha onVerify={this.verifyYouReNotABot} />
                                                <Button onClick={this.props.handleClose}
                                                    color="primary"
                                                    aria-label={langStore.KDAE_CareFolderView.closePopinButton.ariaLabel}>
                                                    {langStore.KDAE_CareFolderView.closePopinButton.text}</Button>
                                                <div className={classes.wrapper}>
                                                    <Button
                                                        type="submit"
                                                        aria-label={langStore.KDAE_CareFolderView.sendFormButton.ariaLabel}
                                                        variant="contained"
                                                        onClick={this.handleSubmitForm}
                                                        disabled={loadingSave}
                                                        className={buttonClassname}
                                                        color="secondary"
                                                    >{langStore.KDAE_CareFolderView.sendFormButton.text}
                                                        <SendRounded className={classes.rightIcon} ></SendRounded>
                                                    </Button>
                                                    {loadingSave && <CircularProgress size={24} className={classes.buttonProgress} />}
                                                </div>
                                            </DialogActions>
                                        </form>
                                    </Card>
                                </GridItem>
                            </GridContainer>
                        </div>

                    </div>
                </div>
            </Dialog >
        )
    }

    handleChangeUserRenew = value => {
        this.setState({ userRenew: value })
        if (value === true) {
            this.setState({
                userEmailLabel: this.props.langStore.KDAE_CareFolderView.form.userEmailCatho.text,
                userEmailAriaLabel: this.props.langStore.KDAE_CareFolderView.form.userEmailCatho.ariaLabel,
                userEmailPlaceHolder: this.props.langStore.KDAE_CareFolderView.form.userEmailCatho.placeHolder

            })
        } else {
            this.setState({
                userEmailLabel: this.props.langStore.KDAE_CareFolderView.form.userEmail.text,
                userEmailAriaLabel: this.props.langStore.KDAE_CareFolderView.form.userEmail.ariaLabel,
                userEmailPlaceHolder: this.props.langStore.KDAE_CareFolderView.form.userEmail.placeHolder

            })
        }
    };

    // handlers =====================================================
    handleNoRobotManualy = () => { this.setState({ yourNotABot: true }); alert(`Vous pouvez renvoyer le formulaire`); }
    handleSubmitForm = (event) => {

        event.preventDefault();
        let { error, fieldsOnError } = this.checkField('all')
        if (this.state.yourNotABot === false) {
            // add multiple actions to one snackbar
            const action = (key) => (
                <Fragment>
                    <Button onClick={this.handleNoRobotManualy} color="secondary" variant="outlined">
                        {'Je ne suis pas un robot'}
                    </Button>
                </Fragment>
            );
            let message = `Il semble que votre terminal de saisie ne soit pas reconnu par l'outil antispam de Google.
                veuillez cliquer ici
            `
            this.props.enqueueSnackbar(message, { action })
            return
        }
        const { langStore } = this.props
        if (this.state.establishmentId === 0 && !!this.state.establishmentIdError === false) {
            fieldsOnError.push('establishmentId')
            error |= true
            this.setState({ establishmentIdError: true })
        }

        if (!!error === true) {
            let message = `Le formulaire contient des erreurs`
            let variant = 'warning'
            this.props.enqueueSnackbar(message, { variant })
            try {
                this.inputWrapper.querySelector(`[name=${fieldsOnError[0]}]`).focus();
            } catch (error) { console.log(error) }
            return
        }
        if (!this.state.loadingSave) {
            let self = this;
            this.setState(
                {
                    success: false,
                    loadingSave: true,
                },
                async () => {
                    let apiResponseNetwork
                    let apiUrl = config.webhook.addCareFolder.url
                    try {
                        apiResponseNetwork = await superagent
                            .post(apiUrl)
                            .set('Content-Type', 'application/json')
                            .send({
                                careFolderToken: window.sessionStorage.careFolderToken || '',
                                userBirthDay: this.state.userBirthDay,
                                userEmail: this.state.userEmail,
                                userPassword: this.state.userPassword,
                                userFirstName: this.state.userFirstName,
                                userGender: this.state.userGender,
                                userLastName: this.state.userLastName,
                                userMobilePhone: this.state.userMobilePhone,
                                userOptinMAH: this.state.userOptinMAH,
                                userProductPackage1: this.state.userProductPackage1,
                                userProductPackage2: this.state.userProductPackage2,
                                userProductPackage3: this.state.userProductPackage3,
                                userProductPackage4: this.state.userProductPackage4,
                                userProductPackage5: this.state.userProductPackage5,
                                userProductPackage6: this.state.userProductPackage6,
                                userProductPackage7: this.state.userProductPackage7,
                                userProductPackage8: this.state.userProductPackage8,
                                userProductPackage7Detail: this.state.userProductPackage7Detail,
                                establishmentId: this.state.establishmentId,
                                userSchoolYear: this.state.userSchoolYear,
                                userTypeHandicap: this.state.userTypeHandicap,
                                userSchoolYearOther: this.state.userSchoolYearOther,
                                userTrainingLabel: this.state.userTrainingLabel,
                                userMicrosoftExternalId: this.state.userMicrosoftExternalId,
                                userGoogleExternalId: this.state.userGoogleExternalId

                            })
                            //   .set('X-JWT', window.localStorage.getItem('JWT'))
                            .withCredentials()
                    }
                    catch (error) {
                        let message = `Une erreur réseau empêche l'envoi du formulaire.`
                        let variant = 'error'
                        let timeout = 1500
                        self.props.enqueueSnackbar(message, { variant })
                        setTimeout(() => {
                            self.setState({
                                loadingSave: false,
                                success: false
                            });
                        }, timeout);
                        return
                    }
                    let responseKeepapi = apiResponseNetwork.body
                    let message, variant, timeout
                    if (responseKeepapi.code !== 'ok') {
                        message = `Une erreur s'est produite lors de l'enregistrement`
                        variant = 'warning'
                        timeout = 1200

                    } else {
                        message = `Les modifications ont bien été enregistrées.`
                        variant = 'success'
                        timeout = 2000
                        sessionStorage.setItem('careFolderToken', responseKeepapi.data)
                    }
                    self.props.enqueueSnackbar(message, { variant })
                    setTimeout(() => {
                        self.setState({
                            loadingSave: false,
                            success: false
                        }, self.props.handleClose(responseKeepapi.code === 'ok'));
                    }, timeout);
                }
            )
        }
    }
    handleInputChange = name => value => {
        this.setState({ [name]: value }, () => this.checkField(name))
    }
    checkField = (name = 'all') => {
        let error = false;
        let fieldsOnError = []
        const { langStore } = this.props
        let { socialNetworkSubscription, userFirstName, userLastName, userMobilePhone, userEmail, userPassword, userBirthDay, userSchoolYear, userSchoolYearOther, userTrainingLabel, userProductPackage7, userProductPackage7Detail, establishmentId, establishmentIdError } = this.state
        // userFirstName
        if (name === 'all' || name === 'userFirstName') {
            if (userFirstName === '' || !userFirstName.match(/.{1,}/)) {
                error |= true
                fieldsOnError.push('userFirstName')
                this.setState({ userFirstNameTextMessage: langStore.KDAE_CareFolderView.form.userFirstName.textMessage, userFirstNameError: true })
            } else {
                this.setState({ userFirstNameTextMessage: "", userFirstNameError: false })
            }
        }
        // userLastName
        if (name === 'all' || name === 'userLastName') {
            if (userLastName === '' || !userLastName.match(/.{1,}/)) {
                error |= true
                fieldsOnError.push('userLastName')
                this.setState({ userLastNameTextMessage: langStore.KDAE_CareFolderView.form.userLastName.textMessage, userLastNameError: true })
            } else {
                this.setState({ userLastNameTextMessage: "", userLastNameError: false })
            }
        }

        // userMobilePhone
        if (name === 'all' || name === 'userMobilePhone') {
            if (userMobilePhone === '' || !userMobilePhone.match(/\+{0,1}[0-9 ]{16,}/)) {
                error |= true
                fieldsOnError.push('userMobilePhone')
                this.setState({ userMobilePhoneTextMessage: langStore.KDAE_CareFolderView.form.userMobilePhone.textMessage, userMobilePhoneError: true })
            } else {
                this.setState({
                    userMobilePhoneTextMessage: `
                
                
                `, userMobilePhoneError: false
                })
            }
        }

        // userEmail
        if (name === 'all' || name === 'userEmail') {
            if (userEmail === '' || !userEmail.match(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,6}$/i)) {
                error |= true
                fieldsOnError.push('userEmail')
                this.setState({ userEmailTextMessage: langStore.KDAE_CareFolderView.form.userEmail.textMessage, userEmailError: true })
            } else {
                this.setState({ userEmailTextMessage: '', userEmailError: false })
            }
        }
        // userPassword
        if (name === 'all' || name === 'userPassword') {
            if (!!socialNetworkSubscription === false) {
                if (typeof userPassword === 'undefined' || userPassword === '' || !userPassword.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-.+~{[|\\^\]}%!@#$%^&*;,_])(?=.{5,})/)) {
                    error |= true
                    fieldsOnError.push('userPassword')
                    this.setState({ userPasswordTextMessage: langStore.KDAE_CareFolderView.form.userPassword.textMessage, userPasswordError: true })
                } else {
                    this.setState({ userPasswordTextMessage: '', userPasswordError: false })
                }
            }
        }
        // userSchoolYearOther
        if (name === 'all' || name === 'userSchoolYear' || name === 'userSchoolYearOther') {
            if (userSchoolYear === 'Other') {
                this.setState({ userSchoolYearOtherRequired: true })
            }
            else {
                this.setState({ userSchoolYearOtherRequired: false })
            }
            if (userSchoolYear === 'Other' && !userSchoolYearOther.match(/.{1,}/)) {
                error |= true
                fieldsOnError.push('userSchoolYearOther')
                this.setState({ userSchoolYearOtherTextMessage: langStore.KDAE_CareFolderView.form.userSchoolYearOther.textMessage, userSchoolYearOtherError: true })
            } else {
                this.setState({ userSchoolYearOtherTextMessage: '', userSchoolYearOtherError: false })
            }
        }
        if (name === 'all' || name === 'userTrainingLabel') {
            if (userTrainingLabel === '' || !userTrainingLabel.match(/.{1,}/)) {
                error |= true
                fieldsOnError.push('userTrainingLabel')
                this.setState({ userTrainingLabelTextMessage: langStore.KDAE_CareFolderView.form.userTrainingLabel.textMessage, userTrainingLabelError: true })
            } else {
                this.setState({ userTrainingLabelTextMessage: "", userTrainingLabelError: false })
            }
        }
        // userProductPackage7 && userProductPackage7Detail
        if (name === 'all' || name === 'userProductPackage7' || name === 'userProductPackage7Detail') {
            if (userProductPackage7 === true) {
                this.setState({ userProductPackage7DetailRequired: true })
            }
            else {
                this.setState({ userProductPackage7DetailRequired: false })
            }
            if (userProductPackage7 === true && !userProductPackage7Detail.match(/.{1,}/)) {
                error |= true
                fieldsOnError.push('userProductPackage7Detail')
                this.setState({ userProductPackage7DetailTextMessage: langStore.KDAE_CareFolderView.form.userProductPackage7Detail.textMessage, userProductPackage7DetailError: true })
            } else {
                this.setState({ userProductPackage7DetailTextMessage: ``, userProductPackage7DetailError: false })
            }
        }
        // userBirthday
        if (name === 'all' || name === 'userBirthDay') {
            if (userBirthDay === '' || moment().diff(userBirthDay, 'years') < 10) {
                error |= true
                fieldsOnError.push('userBirthday')
                this.setState({ userBirthDayTextMessage: langStore.KDAE_CareFolderView.form.userBirthDay.textMessage, userBirthDayError: true })
            } else {
                this.setState({ userBirthDayTextMessage: "", userBirthDayError: false })
            }
        }


        if (name === 'all' || name === 'establishmentId') {
            if (establishmentId === 0 && !!establishmentIdError === true) {
                error |= true
                fieldsOnError.push('establishmentId')
                this.setState({ establishmentIdTextMessage: langStore.KDAE_CareFolderView.form.establishmentId.textMessage, establishmentIdError: true })
            } else {
                this.setState({ establishmentIdTextMessage: "", establishmentIdError: false })
            }
        }


        return { error: !!error, fieldsOnError }
    }

    verifyYouReNotABot = (token) => {
        console.log("google says that you re not a bot")
        if (token) this.setState({ yourNotABot: true })
    }

}

KDAE_CareFolderView.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withWidth()(withStyles(loginPageStyle)(withStyles(styles)(withSnackbar(withLangStore(KDAE_CareFolderView)))))

