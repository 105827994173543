// https://libraries.io/npm/react-phone-input-mui
import React from 'react';
import ReactPhoneInput from 'react-phone-input-mui';

import { TextField, withStyles, FormControl } from '@material-ui/core';

const styles = theme => ({
  field: {
    // marginTop: theme.spacing.unit * 2,
  },
  countryList: {
    ...theme.typography.body1,
  },
  hasError:{
    top:'70px'
  }
});


function KDAEPhone(props) {
  const { value, defaultCountry, handleInputChange, classes, helperText, error, label, ...onlyOtherProps } = props;
  return (
    <FormControl className={classes.field} {...onlyOtherProps}>
      {/* Configure more */}
      <ReactPhoneInput
        value={value}
        defaultCountry={defaultCountry || 'fr'}
        onChange={handleInputChange}

        dropdownClass={error===true?classes.hasError:''}
        component={TextField}
        inputExtraProps={{
          margin: 'normal',
          autoComplete: 'phone',
          name: 'custom-username',
          helperText,
          error,
          label
        }}
        {...onlyOtherProps}
      />
    </FormControl>
  );
}

export default withStyles(styles)(KDAEPhone);