
import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { FormControl } from '@material-ui/core';

import {  ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
// import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import theme from '../../assets/ui/themes'

class KDAETextField extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: this.props.initialValue,
    };
  }
  handleChange = event => {
    this.setState({ name: event.target.value });
    if(this.props.handleInputChange)
      this.props.handleInputChange(event.target.value)
  };

  render() {
    let { fullWidth, initialValue,InputProps, ...onlyOtherProps } = this.props
    return (
      <MuiThemeProvider theme={theme}>
      <FormControl fullWidth={fullWidth} >
        <TextField
          InputProps={{...InputProps}}
          value={this.state.name}
          onChange={this.handleChange}
          // margin="normal"
          {...onlyOtherProps}

        />
      </FormControl>
      </MuiThemeProvider>
    );
  }
}

KDAETextField.propTypes = {
  classes: PropTypes.object,
};

export default KDAETextField