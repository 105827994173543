import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import MUIButton from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'

// import Loading from '../Loading'

import styles from './styles'


const Button = forwardRef(({
  small,
  color,
  variant,
  iconSrc,
  classes,
  children,
  disabled,
  isLoading,
  className,
  fixedWidth,
  iconClassName,
  ...props
}, ref) => {
  const MuiColor = ['default', 'primary', 'secondary'].includes(color) ? color : 'default'

  return (
    <MUIButton
      className={classNames(classes.root, className, {
        [classes.smallButton]: small,
        [classes.fixedWidth]: fixedWidth,
      }, classes[color], classes[variant])}
      color={MuiColor}
      variant={variant}
      {...props}
      disabled={disabled || isLoading}
    >
      <div className={classNames(classes.hover, classes[color], classes[variant])} />
      <div className={classes.content} ref={ref}>
        {/* {isLoading && <Loading size={16} className="mr1" />} */}
        {children}
        {iconSrc && <img alt="button icon" src={iconSrc} className={classNames(classes.icon, iconClassName)} />}
      </div>
    </MUIButton>
  )
})


Button.propTypes = {
  small: PropTypes.bool,
  color: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  iconSrc: PropTypes.string,
  isLoading: PropTypes.bool,
  fixedWidth: PropTypes.bool,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(Button)
