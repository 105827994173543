module.exports = {
  'conjunctionOr': 'or',
  'loginForm': {

    'title1': '',
    'title2': 'Login',
    'fillAllBlanks': 'Fill in the following fields',
    'subscribeNow': 'register here',
    'connectNow': 'sign up here',
    'titleSocials': 'Or log-in directly via',
    'socialsCgu': 'En vous connectant via votre compte de réseaux sociaux, vous déclarez accepter les conditions générales d"utilisations',
    'loginAction': 'Login',
    'registerAction': `Apply for registration`,
    'logout': 'Log-out',
    'loginOr': 'Login or',
    'or': 'or',
    'signUp': 'Sign up',
    'rememberMe': 'Remember me',
    'forgotPassword': 'Forgot password ?',
    'error': {
      'password': 'password required',
      'passwordStrength': 'Password must be at least 6 characters long, containing uppercase, lowercase letters, numbers and special characters (for example :!*$)',
      'firstName': 'First name is missing',
      'lastName': 'Last name is missing',
      'email': `Email required. If you don't have an instutionnal email address, you can temporaly use a personal one`,
      'oauth': {
        'access_denied': 'You have refused the connection, we can not identify you. Please accept it in order to be able to log-in',
        'not_found': `We haven't find your profil on our system. Please contact supervisor if the problem persists or use the forgot password link.`,
        'notRegister': `We can not create your profile. Please contact the supervisor or try again later.`
      }
    },
    firstName: `First name ...`,
    lastName: `Last name ...`,
    email: `Email ...`,
    password: `Password ...`,

  },
  'KMPopupForgotPassword': {
    userEmailLabel: {
      text: `Enter your email`,
      speech: [

      ],
      ariaLabel: `Enter your email`
    },
    expireTitle: {
      text: `You want your password to expire ?`,
      speech: [

      ],
      ariaLabel: `You want your password to expire ?`
    },
    expireSubTitle: {
      text: `Forgot password ?`,
      speech: [

      ],
      ariaLabel: `Forgot password ?`
    }
    ,
    expireDescription: {
      text: `Enter your email address`,
      speech: [

      ],
      ariaLabel: `Enter your email address`
    },
    closePopin: {
      text: `close`,
      speech: [

      ],
      ariaLabel: `close`
    },
    resendMessaegAction: {
      text: `send me my new password`,
      speech: [

      ],
      ariaLabel: `send me my new password`
    }
  },
  'HeaderLinks': {
    enableTextToSpeech: {
      text: `Text to speech`,
      speech: [
        { speech: `Read aloud text enabled.`, queue: true },
        { speech: `You can double click, or click more than 1 second,  on the texts of the site. You also can click on this icon to have a vocalized version of the text.`, queue: true },
        { speech: `For optimal voice rendering feature, use the Chrome browser if possible.`, queue: true }
      ],
      ariaLabel: `Enable read aloud text`
    },
    accessibilityRegister: {
      text: `Accessibility register`,
      speech: [
        { speech: `Accessibility register.`, queue: false },
      ],
      ariaLabel: `link to accessibility register of university catholique de Lille`
    },
    referentMAH: {
      text: `Our MAH referees`,
      speech: [
        { speech: `Our MAH referees.`, queue: false },
      ],
      ariaLabel: `Link to our MAH referees`
    }
  },

  'KDAE_CareFolderView': {
    closePopinButton: {
      text: `Close`,
      speech: [
      ],
      ariaLabel: 'Close this popin without sending the form'
    },
    sendFormButton: {
      text: `Submit`,
      speech: [
      ],
      ariaLabel: "Button that submit this form"
    },

    userOptinMAH: {
      text: `By checking this box, I accept to receive by email, informations about the "Mission Accueil Handicap", invitations to events, internships and job offers ...`,
      speech: [
        { speech: `By checking this box, I accept to receive by email, informations about the "Mission Accueil Handicap", invitations to events, internships and job offers ...`, queue: true }
      ]
    },
    userProductPackage1: {
      ariaLabel: `Accessibility.  (For example: elevator, exam room on the ground floor, isolated room ...)`,
      text: `<div><b>Accessibility</b> (Ex: elevator, exam room on the ground floor, isolated room ...)</div>`,
      speech: [
        { speech: `Accessibility of the premises.  (For example: elevator, exam room on the ground floor, isolated room ...)`, queue: true }
      ]
    },
    userProductPackage2: {
      ariaLabel: `Special installation of the room. . (Ex: special lighting, proximity of toilets ...)`,
      text: `<div><b>Special installation of the room. </b> (Ex: special lighting, proximity of toilets ...)</div>`,
      speech: [
        { speech: `Special installation of the room. . (Examples: special lighting, proximity of toilets ...)`, queue: true }
      ]
    },
    userProductPackage3: {
      ariaLabel: `Special installation of equipment. (Ex: magnifying glass, computer, software ...)`,
      text: `<div><b>Special installation of equipment.</b> (Ex: magnifying glass, computer, software ...)</div>`,
      speech: [
        { speech: `Special installation of equipment. (Examples: magnifying glass, computer, software ...)`, queue: true }
      ]
    },
    userProductPackage4: {
      ariaLabel: `Third party person`,
      text: `<div><b>Third party person</b></div>`,
      speech: [
        { speech: `Third party person`, queue: true }
      ]
    },
    userProductPackage5: {
      ariaLabel: `enlargement of documents`,
      text: `<div><b>enlargement of documents</b></div>`,
      speech: [
        { speech: `enlargement of documents`, queue: true }
      ]
    },
    userProductPackage6: {
      ariaLabel: `Extra time for exams`,
      text: `<div><b>Extra time for exams</b></div>`,
      speech: [
        { speech: `Extra time for exams`, queue: true }
      ]
    },
    userProductPackage8: {
      ariaLabel: `Internship situation abroad`,
      text: `<div><b>Internship situation abroad</b></div>`,
      speech: [
        { speech: `Internship situation abroad`, queue: true }
      ]
    },
    userProductPackage7: {
      ariaLabel: `Others`,
      text: `<b>Others</b>`,
      speech: [
        { speech: `Others`, queue: true }
      ]
    },
    title: {
      text: `Arrange your courses and exams`,
      speech: [
        { speech: `This form allows you to initiate or renew your accommodation requests for your courses and exams`, queue: false }
      ],
      ariaDescribedby: `form-allowing-to-learn-and-renew-your-request-d'aménagement-for-your-course-or-exams`
    },
    subscribeWithSocialNetwork: {
      text: `Subscribe with social network button`,
      speech: [
        { speech: `Subscribe with social network button`, queue: false }
      ],
      ariaLabelledby: `formulaire-d-inscription-et-de-demande-d-amenagements`
    },
    subscribeWithEmailAndPassword: {
      text: `create account with email and password`,
      speech: [
        { speech: `create account with email and password`, queue: false }
      ],
      ariaLabelledby: `formulaire-d-inscription-et-de-demande-d-amenagements`
    },
    subscribeWithSocialNetworkActif: {
      text: `You will connect with ___externalProvider___ button.
      <br />University will contact you with this email: <b>___userEmail___</b>
      `,
      speech: [
        {
          speech: `You will connect with ___externalProvider___
        University will contact you with this email: ___userEmail___
        `, queue: false
        }
      ],
      ariaLabelledby: `formulaire-d-inscription-et-de-demande-d-amenagements`
    },
    subscribeWithSocialNetworkChooseAnotherMethod: {
      text: `Choose another email address`,
      speech: [
        {
          speech: `Choose another email address`, queue: false
        }
      ],
      ariaLabelledby: `formulaire-d-inscription-et-de-demande-d-amenagements`
    },
    explain: {
      text: `This form allows you to initiate or renew your accommodation requests for your courses and exams`,
      speech: [
        { speech: `This form allows you to initiate or renew your accommodation requests for your courses and exams`, queue: false }

      ],
      ariaLabelledby: `form-of-entry-and-demand-and-facilities`
    },
    introItemsText: {
      text: `Choose the topics you wish to discuss with the university doctor.`,
      speech: [
        { speech: `Choose the topics you wish to discuss with the university doctor.`, queue: false }
      ],
      ariaLabelledby: `choose-subjects`
    },

    form: {
      userFirstName: {
        text: `First name`,
        speech: [
        ],
        ariaLabel: 'First name',
        textMessage: 'The field "First name" is empty'
      },
      userLastName: {
        text: `Last name`,
        speech: [
        ],
        ariaLabel: 'Last name',
        textMessage: 'The field "Last name" is empty'
      },
      userPassword: {
        text: `Mot de passe`,
        speech: [

        ],
        ariaLabel: 'mot de passe',
        textMessage: 'Password must be at least 6 characters long, containing uppercase, lowercase letters, numbers and special characters (for example :!*$)'
      },
      userBirthDay: {
        text: `Date of birth`,
        speech: [
        ],
        ariaLabel: 'Date of birth'
      },
      establishmentId: {
        text: `Choose from drop-down list`,
        textMessage: 'The establishment field is required',
        speech: [
        ],
        label: 'Establishment',
        ariaLabel: 'Establishment'
      },
      userGender: {
        text: `Civility`,
        speech: [
        ],
        ariaLabel: 'Civility'
      },
      userGenderMale: {
        text: `Mr`,
        speech: [
        ],
        ariaLabel: ''
      },
      userGenderFemale: {
        text: `Mrs`,
        speech: [
        ],
        ariaLabel: ''
      },
      userRenew: {
        text: `Is it a renewal application?`,
        speech: [
        ],
        ariaLabel: 'Renewal request'
      },
      userRenewYes: {
        text: `Yes`,
        speech: [
        ],
        ariaLabel: ''
      },
      userRenewNo: {
        text: `No`,
        speech: [
        ],
        ariaLabel: ''
      },
      userEmail: {
        text: `Email`,
        speech: [
        ],
        ariaLabel: 'Email',
        placeHolder: 'Email',
        textMessage: `Email required.`
      },
      userEmailCatho: {
        text: `Your  instutionnal email address,( If you don’t have an instutionnal email address, you can temporaly use a personal one)`,
        speech: [
        ],
        ariaLabel: 'Mail of "la catho"',
        placeHolder: 'email'
      },
      userMobilePhone: {
        text: `Phone number`,
        speech: [
        ],
        ariaLabel: 'Phone number',
        textMessage: 'Phone number is not valid'
      },
      userSchoolYear: {
        text: `School Year`,
        speech: [
        ],
        ariaLabel: 'School year. Year 1, Year 2, Year 3 of Bachelor, Master Degree 1, Master Degree 2, Exchange Programm, Others (to specify)'
      },
      userTypeHandicap: {
        text: `Type of demand`,
        speech: [
        ],
        ariaLabel: 'Type of request: continuing education, year-round, one-off'
      },
      userSchoolYearOther: {
        text: `Other. To specify :`,
        speech: [
        ],
        ariaLabel: 'Other. To specify',
        textMessage: 'Field required'
      },
      userTrainingLabel: {
        text: `Title of your training:`,
        speech: [

        ],
        ariaLabel: 'Title of your training:',
        textMessage: 'Field required'
      },
      KMEstablishmentId: {
        text: `Choose an establishment from the drop-down list:`,
        speech: [

        ],
        ariaLabel: 'Choose an establishment from the drop-down list:',
        textMessage: 'Field required'
      },
      userProductPackage7Detail: {
        text: `To specify :`,
        speech: [
        ],
        ariaLabel: 'To specify',
        textMessage: 'Field required'
      }
    }


  },


  'home': {
    login: {
      text: `Hello, Please Log-in`,
      speech: [
      ],
      ariaDescribedby: ``,
      ariaLabelledby: ``

    },
    title: {
      text: `
Application form
for students
with disabilities
      `,
      speech: [
        { speech: `Planning application form for students with disabilities`, queue: false }
      ]
    },
    mahLogo: {
      text: `Logo of "Mission Accueil Handicap de l'univérsité Catholique de Lille`,
      speech: [
      ]
    },
    intro: {
      text: `
L'Université Catholique de Lille encourage everyone access to higher education.
This form will provide your school or faculty with your personnal informations. Your school/faculty is the final authority and will let your know what can be applied in regards of your pedagogical project
`,
      speech: [
        { speech: `L'Université Catholique de Lille" encourage everyone access to higher education. This form will provide your school or faculty with your personnal informations. `, queue: true },
        { speech: `Your school/faculty is the final authority and will let your know what can be applied in regards of your pedagogical project`, queue: true }
      ]
    },

    step1TabButton: {
      text: `Step one`,
      speech: [

      ]
    },
    step1Title: {
      text: `Sign-up`,
      speech: [

      ]
    },
    step1Alt: {
      text: `Step two. Register by following the sign up button`,
      speech: [

      ]
    },
    step1RenewTitle: {
      text: `Login`,
      speech: [

      ]
    },
    step1RenewAlt: {
      text: `Step one. Log in by following the renewal button`,
      speech: [

      ]
    },
    step1RenewButtonLabel: {
      text: `Button that opens the form's pop-in to connect in case of a renewal.`,
      speech: [
      ]
    },
    step1ButtonLabel: {
      text: `Button that opens the form's pop-in for a first request.`,
      speech: [
      ]
    },
    step1: {
      text: `Fill in the form below to initiate your accommodation request`,
      speech: [
        { speech: `Step one. Fill in the form below to initiate your accommodation request`, queue: true },
        { speech: `or renew your last year form`, queue: true }
      ]
    },
    step1Renew: {
      text: `Log in and renew your requests`,
      speech: [
        { speech: `Step one. Log in and renew your requests`, queue: false }
      ]
    },

    step1More: {
      text: `
This request will have to be renewed every year.

After submitting your form online do not forget to make an appointment with the university doctor
`,
      speech: [
        { speech: `This request will have to be renewed every year.`, queue: true },
        { speech: `After submitting your form online do not forget to make an appointment with the university doctor. (see step 2)`, queue: true }
      ]
    },
    step2TabButton: {
      text: `Step 2`,
      speech: [

      ]
    },
    step2: {
      text: `
MAKE AN APPOINTMENT WITH
THE UNIVERSITY DOCTOR

          `,
      speech: [
        { speech: `Step 2. After submitting your request in step one. Make an appointment with the university doctor at the C.P.S.U. 03, 28, 04, 02, 40.`, queue: false }
      ]
    },
    step2Suite: {
      text: `
03.28.04.02.40
CPSU, 47 bis Rue du Port 59000 Lille
          `,
      speech: [
      ]
    },
    step2More: {
      text: `
The university doctor is register at the « Maison Départementale des Personnes Handicapées ».


The doctor gives an opinion on the facilities requested and makes recommendations that will be sent to your school or faculty.


During the rendez-vous do not forget to come with all the medical documents regarding your pathology.
      `,
      speech: [
        { speech: `The university doctor is register at the « Maison Départementale des Personnes Handicapées ».`, queue: true },
        { speech: `The doctor gives an opinion on the facilities requested and makes recommendations that will be sent to your school or faculty.`, queue: true },
        { speech: `During the rendez-vous do not forget to come with all the medical documents regarding your pathology.`, queue: true }
      ]
    },
    step3TabButton: {
      text: `Step 3`,
      speech: [

      ]
    },
    step3: {
      text: `
The school / faculty makes a decision about accommodations during classes and exams and will inform you
          `,
      speech: [
        { speech: `Step 3. The school makes a decision about accommodations, classes and exams, and will inform you`, queue: true },
        { speech: `The proposals of faculty or school will be sent to you by email. Remember to check your email address during registration (see step 1).`, queue: true }
      ]
    }
    ,
    step3More: {
      text: `
The proposals of the establishments will be sent to you
by email.

Remember to check your email address when registering.
 `,
      speech: [
      ]
    },
    step3Title: {
      text: `Decisions of school / faculty`,
      speech: [

      ]
    },
    step3Alt: {
      text: `Step 3. Decisions of school / faculty`,
      speech: [

      ]
    },
    signUpButton: {
      text: `First Request`,
      speech: [
      ]
    },
    renewButton: {
      text: `Renew`,
      speech: [
      ]
    },

  },
  'legalNotice': {
    title: {
      text: `Legal notice.`,
      speech: [
      ],
      ariaLabelledby: `Legal notice.`
    },
    dpo1: {
      text: `
<br />Les informations recueillies sur ce formulaire sont enregistrées dans une base informatisée par l'Institut Catholique de Lille à des fins d’accompagnement pédagogique et de mise en place des aménagements nécessaires.
<br />
<br />Elles sont conservées durant l’année universitaire en cours.
<br />
<br />La base légale du traitement est le consentement, il est conforme au règlement européen (UE) 2016/679 du 27 avril 2016 relatif à la protection des personnes physiques à l’égard du traitement des données à caractère personnel, et à la loi « Informatique, Fichiers et Libertés » n°78-17 du 6 janvier 1978 modifiée.
<br />
<br />Pour exercer votre droit d'accès aux données vous concernant à tout moment et les faire rectifier ou supprimer en contactant : mah@univ-catholille.fr
<br />
<br />Pour toute question sur le traitement de vos données dans ce dispositif, vous pouvez contacter notre délégué à la protection des données (DPO) : dpo@univ-catholille.fr
<br />
<br />Si vous estimez, après nous avoir contactés, que vos droits Informatique et Libertés ne sont pas respectés, vous pouvez adresser une réclamation en ligne à la CNIL : www.cnil.fr ou par courrier postal ; la CNIL, 3 Place de Fontenoy – TSA 80715 – 75334 PARIS CEDEX 07.
                                          
`,
      speech: [

      ],
      ariaDescribedby: ``,
      ariaLabelledby: ``
    },
    dpo2: {
      text: `

<br />Les informations fournies lors de la création de votre compte sont enregistrées dans une base informatisée par l'Institut Catholique de Lille à des fins de gestion informatique.
<br />
<br />Elles sont conservées tant que votre compte demeure actif.
<br />
<br />La base légale du traitement est le consentement, il est conforme au règlement européen (UE) 2016/679 du 27 avril 2016 relatif à la protection des personnes physiques à l’égard du traitement des données à caractère personnel, et à la loi « Informatique, Fichiers et Libertés » n°78-17 du 6 janvier 1978 modifiée.
<br />
<br />Vous pouvez accéder à votre profil et modifier ou supprimer vos données en allant sur le site mah.univ-catholille.fr puis en cliquant sur l’onglet « votre compte » et le bouton « désactiver mon compte »
<br />
<br />Pour exercer votre droit d'accès aux données vous concernant à tout moment et les faire rectifier ou supprimer en contactant : mah@univ-catholille.fr
<br />
<br />Pour toute question sur le traitement de vos données dans ce dispositif, vous pouvez contacter notre délégué à la protection des données (DPO) : dpo@univ-catholille.fr
<br />
<br />Si vous estimez, après nous avoir contactés, que vos droits Informatique et Libertés ne sont pas respectés, vous pouvez adresser une réclamation en ligne à la CNIL : www.cnil.fr ou par courrier postal ; la CNIL, 3 Place de Fontenoy – TSA 80715 – 75334 PARIS CEDEX 07.
`,
      speech: [

      ],
      ariaDescribedby: ``,
      ariaLabelledby: ``
    },
  }
}
