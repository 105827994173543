// require('dotenv').config()
module.exports = {
  reCaptcha: { key: '6Lf_06kUAAAAAHHbV3SWzBhvtljl4LCIRPfio3x1' },
  mainDomainName: process.env.REACT_APP_DOMAIN_NAME,
  socialNetwork: {
    facebook: {
      appId: process.env.REACT_APP_FACEBOOK_APPID ||  "777934109627485"
    },
    google: {
      clientId: process.env.REACT_APP_GOOGLE_CLIENTID ||  "1007524147148-r7515baluupsi7n8d2531pju99n0ehmg.apps.googleusercontent.com"
    },
    linkedIn: {
      clientId: process.env.REACT_APP_LINKEDIN_CLIENTID ||  ""
    },
    microsoft: {
      clientId: process.env.REACT_APP_MICROSOFT_CLIENTID ||  "5aaf0a0e-56a0-40f4-9d86-d2d057b41898"
    }

  },
  api: {
    sharePublicReferentMAH: {
      url: `https://api.${process.env.REACT_APP_BACKEND_DOMAIN_NAME}/share-public/storage/1/6/6d93a1d4-6e66-11ee-b962-0242ac120002/mah`,
    },
    sharePublicAccessibilityRegister: {
      url: `https://api.${process.env.REACT_APP_BACKEND_DOMAIN_NAME}/share-public/storage/1/7/b1Af53ca-7f09-4a7b-cc43-d72230289f44/998SA`,
    }
  },
  webhook: {
    addCareFolder: {
      url: `https://wh.${process.env.REACT_APP_BACKEND_DOMAIN_NAME}/careFolder/add`,
    },
    forgotPasswordUser: {
      url: `https://wh.${process.env.REACT_APP_BACKEND_DOMAIN_NAME}/user/forgotPassword`,
    },
    resetPasswordUser: {
      url: `https://wh.${process.env.REACT_APP_BACKEND_DOMAIN_NAME}/user/resetPassword`,
    },
  },
  admin: {
    autolog: {
      url: `https://${process.env.REACT_APP_BACKEND_DOMAIN_NAME}/autolog/___TOKEN___`,
    }
  },
  front: {
    establishment: {
      url: `https://api.${process.env.REACT_APP_BACKEND_DOMAIN_NAME}/v1/front/establishment`,
    }
  },
  oauth: {
    logout: {
      url: `https://oauth.${process.env.REACT_APP_BACKEND_DOMAIN_NAME}/logout`,
    },
    login: {
      url: `https://oauth.${process.env.REACT_APP_BACKEND_DOMAIN_NAME}/login`,
    },
    loginWithMicrosoft: {
      url: `https://oauth.${process.env.REACT_APP_BACKEND_DOMAIN_NAME}/loginWithMicrosoft`,
    },
    loginWithGoogle: {
      url: `https://oauth.${process.env.REACT_APP_BACKEND_DOMAIN_NAME}/loginWithGoogle`,
    },
    loginWithLinkedIn: {
      url: `https://oauth.${process.env.REACT_APP_BACKEND_DOMAIN_NAME}/loginWithLinkedIn`,
    },
    register: {
      url: `https://oauth.${process.env.REACT_APP_BACKEND_DOMAIN_NAME}/register`,
    },
    isAuthenticated: {
      url: `https://oauth.${process.env.REACT_APP_BACKEND_DOMAIN_NAME}/isAuthenticated`,
    }
  },
  navigation: {
    front: {
      url: '/'
    }
  },
}
