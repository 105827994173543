/*eslint-disable*/
import withWidth from '@material-ui/core/withWidth';
import profile from "../../assets/img/MAHLogo.png";
import config from "../../config"
import React, {Fragment, useEffect, useState} from "react";
import Speech from "speak-tts";
// react components for routing our app without refresh
import Fab from '@material-ui/core/Fab';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import { Input, VolumeOffRounded, VolumeUpRounded } from "@material-ui/icons";
import ReactFlagsSelect from 'react-flags-select';
//import css module
import 'react-flags-select/css/react-flags-select.css';
// core components
// import CustomDropdown from "../../components/CustomDropdown/CustomDropdown.jsx";
import Button from "../../components/CustomButtons/Button.jsx";

import { withLangStore } from '../../stores/KLangProvider'
import headerLinksStyle from "../../assets/jss/material-kit-react/components/headerLinksStyle.jsx";
// import Test from './Test'
import LoginPageModal from '../../views/LoginPage/LoginPageModal'
import { useLocation, useNavigate } from 'react-router-dom';
import superagent from "superagent";
import {useSnackbar} from "notistack";
import {Box} from "@mui/material";
import Typography from "@material-ui/core/Typography";
function HeaderLinks({ ...props }) {
  const history = useNavigate()
  const location = useLocation()
  const { enqueueSnackbar } = useSnackbar();
  const { classes, langStore } = props;
  React.useEffect(() => {
    if (location.pathname === '/reset/') handleClickOpen()
  }, []);

  const handleSelectLang = countryCode => {
    sessionStorage.setItem('prefsCountryCode', countryCode)
    sessionStorage.setItem('prefsLang', 'fr-FR')
    if (countryCode.toLowerCase() === 'gb') {
      sessionStorage.setItem('prefsLang', 'en-GB')
    }
    window.location.reload()
  }
  const [open, setOpen] = useState(false)
  const [textToSpeechEnable, setTextToSpeechEnable] = useState(!sessionStorage.textToSpeechEnable || sessionStorage.textToSpeechEnable == 'true' ? true : false)
  const disableTextToSpeech = () => {
    setTextToSpeechEnable(false)
    sessionStorage.setItem('textToSpeechEnable', false)
  }
  const enableTextToSpeech = () => {
    setTextToSpeechEnable(true)
    sessionStorage.setItem('textToSpeechEnable', true)
    handleSpeech(1)
  }

  const handleClickOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }
  const handleSpeech = (sentenceNumber) => {
    let textToSpeech = []
    const { langStore } = props
    switch (sentenceNumber) {
      case 1:
        textToSpeech = langStore.HeaderLinks.enableTextToSpeech.speech
        break;

      default:
        textToSpeech = []
    }
    if (textToSpeech.length < 1) return
    const speech = new Speech();
    // speech.setVoice('Fiona')
    let lang = sessionStorage.prefsLang || 'fr_FR'
    speech.setLanguage(lang)
    speech.init({
      'lang': lang
    }).then(data => {
      console.log("Speech is ready", data);
    })
    textToSpeech.map(textToSpeech =>
      speech
        .speak({
          text: textToSpeech.speech,
          queue: textToSpeech.queue,
          listeners: {
            onstart: () => {
              console.log("Start utterance");
            },
            onend: () => {
              console.log("End utterance");
            },
            onresume: () => {
              console.log("Resume utterance");
            },
            onboundary: event => {
              console.log(
                event.name +
                " boundary reached after " +
                event.elapsedTime +
                " milliseconds."
              );
            }
          }
        })
        .then(data => {
          console.log("Success !", data);
        })
        .catch(e => {
          console.error("An error occurred :", e);
        })
    )
  }
  const myWidth = props.width === 'sm' || props.width === 'xs' ? 'smartphone' : 'large'

  const downloadFile = async (docType) => {
    let apiUrl = config.api.sharePublicReferentMAH.url;
    try {
      const response = await superagent
          .get(apiUrl)
          .set('Content-Type', 'application/json')
          .set('Accept', 'application/json')
          .query({ docType });

      if (response.body.length > 0) {
        const datas = response.body;
        const doc = datas[0];

        const fileResponse = await superagent
            .get(doc.docTemporarySignedUrl)
            .responseType('arraybuffer');

        const blob = fileResponse.body;
        const streamURL = window.URL.createObjectURL(new Blob([blob], { type: 'application/octet-stream' }));
        const streamLink = document.createElement('a');
        streamLink.href = streamURL;
        streamLink.setAttribute('download', `${doc.docLabel}.pdf`);
        document.body.appendChild(streamLink);
        streamLink.click();

        setTimeout(() => {
          document.body.removeChild(streamLink);
          window.URL.revokeObjectURL(streamURL);
        }, 100);
      } else {
        const message = `Impossible de récupérer la liste de nos référents`;
        enqueueSnackbar(message, { variant: 'warning' });
      }
    } catch (error) {
      console.error('Erreur lors de la requête:', error);
    }
  };


  return (
    <List className={classes.list}>
      {(props.isModal === false || (props.isModal === true && myWidth === 'smartphone')) &&
        <Fragment>
          <ListItem className={classes.listItem}>
            <Button
              // color="transparent"
              className={classes.navLink}
              variant="outlined"
              color="primary" onClick={handleClickOpen}
            >
              <Input className={classes.icons} />
              {langStore.home.login.text}
            </Button>

          </ListItem>
          <ListItem className={classes.listItem} >
            <ReactFlagsSelect
              countries={["GB", "FR"]}
              customLabels={{ "GB": "Anglais", "FR": "Français" }}
              defaultCountry={sessionStorage.prefsCountryCode || 'FR'}
              showOptionLabel={true}
              selectedSize={20}
              optionsSize={16}
              showSelectedLabel={true}
              onSelect={handleSelectLang}
              className={classes.flagSelect}
            />
          </ListItem>
          <ListItem className={classes.listItem}>
            {textToSpeechEnable === true ? (
              <Fab size="small" color="primary" aria-label="Désactiver le text to Speech" className={classes.margin} onClick={disableTextToSpeech}>
                <VolumeUpRounded></VolumeUpRounded>
              </Fab>
            ) : (
                <Fab size="small" color="primary" aria-label={langStore.HeaderLinks.enableTextToSpeech.ariaLabel} className={classes.margin} onClick={enableTextToSpeech}>
                  <VolumeOffRounded ></VolumeOffRounded>
                </Fab>
              )
            }
            {/* {myWidth === 'smartphone' && */}
            {textToSpeechEnable === true ? (
              <b onClick={disableTextToSpeech}>&nbsp;{langStore.HeaderLinks.enableTextToSpeech.text}</b>
            ) : (
                <b onClick={enableTextToSpeech}>&nbsp;{langStore.HeaderLinks.enableTextToSpeech.text}</b>

              )
            }
            {/* } */}



          </ListItem>
          <ListItem className={classes.listItem}>
            <Typography className={classes.buttonMAH}  variant={'body2'} onClick={() => downloadFile('referentPDF')}  color="transparent" aria-label={langStore.HeaderLinks.referentMAH.text}>
              <Box display="flex" alignItems="center">
                <img src={profile} alt={langStore.home.mahLogo.text} className={classes.imgMAH}/>
                <b>&nbsp;{langStore.HeaderLinks.referentMAH.text}</b>
              </Box>
            </Typography>

          </ListItem>
          <ListItem className={classes.listItem}>
            <Typography className={classes.accessibilityRegister}  variant={'body2'}  onClick={() => downloadFile('AccessibilityRegister')}  color="transparent" aria-label={langStore.HeaderLinks.accessibilityRegister.text}>
              <Box display="flex" alignItems="center">

                <b>
                  &nbsp;{langStore.HeaderLinks.accessibilityRegister.text}</b>
              </Box>
            </Typography>

          </ListItem>
          <LoginPageModal

            handleClose={handleClose} open={open} />
        </Fragment>

      }
    </List>
  );

}


export default withWidth()(withStyles(headerLinksStyle)(withLangStore(HeaderLinks)))
