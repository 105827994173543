import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import GoogleLogin from 'react-google-login'
import { withStyles } from '@material-ui/core/styles'

import imageSrc from 'assets/img/social/google-16.svg'
import Button from '.'
import styles from './styles'


const GoogleButton = ({
  text,
  classes,
  render,
  onClick,
  clientId,

  className,
  ...props
}) => {
  const responseGoogle = (response) => {
    debugger
    console.log('--- google', response)

    if (response.error || !response.googleId) {
      return null
    }

    return onClick && onClick({
      externalProvider: 'google',
      externalId: response.googleId,
      externalAccessToken: response.accessToken,
      email: response.profileObj.email,
      profile: {
        imageUrl: response.profileObj.imageUrl,
        lastName: response.profileObj.familyName,
        firstName: response.profileObj.givenName,
      },
    })
  }
  const defaultRenderer = renderProps => (
    <Button
      variant="contained"
      onClick={renderProps.onClick}
      disabled={renderProps.disabled}
      className={classNames(classes.google, classes.socialNetwork, className)}
      {...props}
    >
      <img src={imageSrc} alt={text} className={classes.socialNetworkIcon} />
      {text}
    </Button>
  )

  return (
    <GoogleLogin
      clientId={clientId}
      // buttonText={text}
      onSuccess={responseGoogle}
      onFailure={responseGoogle}
      render={render || defaultRenderer}
      {...props}

    />
  )
}


GoogleButton.propTypes = {
  clientId: PropTypes.string,
  text: PropTypes.string,
  render: PropTypes.func,
  onClick: PropTypes.func,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(GoogleButton)
